import { conversationList } from '@/services/Conversations/store'
import { conversationAttachmentStore } from '@/services/Conversations/ConversationAttachment/store'
import { notifyError, notifyProblem, notifyMessage } from '@/services/notify'
import $ from 'jquery'
import _get from 'lodash/get'

export const methods = {
  onRecipientChange() {},

  addRecipient(recipient) {
    console.debug('add recipient=' + recipient)

    let added = false

    if (this.recipientsSelected) {
      const list = this.recipientsSelected
      for (let a = 0; a < list.length; a++) {
        const tempHolder = list[a]

        if (tempHolder.recipient === recipient) {
          added = true
          break
        }
      }
    }

    if (!added) {
      if (recipient === 'Homeowners') {
        if (
          this.selectedOwnerIDsJson &&
          Array.isArray(this.selectedOwnerIDsJson) &&
          this.selectedOwnerIDsJson.length > 0
        ) {
          Array.from(this.selectedOwnerIDsJson).forEach(element => {
            if (element) {
              const newRecipient = `Homeowner - ${element.name}`
              const isThisAlreadyInArray = this.recipientsSelected.filter(
                f => f.recipient === newRecipient
              )

              if (isThisAlreadyInArray && isThisAlreadyInArray.length === 0) {
                this.recipientsSelected.push({
                  recipient: newRecipient,
                  type: 'homeowner',
                  id: element.ownerID
                })
              }
            }
          })
        }
      } else if (recipient === 'Vendors') {
        if (
          this.selectedVendorIDsJson &&
          Array.isArray(this.selectedVendorIDsJson) &&
          this.selectedVendorIDsJson.length > 0
        ) {
          Array.from(this.selectedVendorIDsJson).forEach(element => {
            if (element) {
              const newRecipient = `Vendor - ${element.name}`
              const isThisAlreadyInArray = this.recipientsSelected.filter(
                f => f.recipient === newRecipient
              )

              if (isThisAlreadyInArray && isThisAlreadyInArray.length === 0) {
                this.recipientsSelected.push({
                  recipient: newRecipient,
                  type: 'vendor',
                  id: element.vendorID
                })
              }
            }
          })
        }
      } else if (recipient === 'Management Portal Users') {
        console.log('Management Portal Users=' + JSON.stringify(this.selectedAccountManagerIDsJson))
        if (
          this.selectedAccountManagerIDsJson &&
          Array.isArray(this.selectedAccountManagerIDsJson) &&
          this.selectedAccountManagerIDsJson.length > 0
        ) {
          Array.from(this.selectedAccountManagerIDsJson).forEach(element => {
            if (element) {
              const newRecipient = `Management Portal User - ${element.name}`
              const isThisAlreadyInArray = this.recipientsSelected.filter(
                f => f.recipient === newRecipient
              )

              console.log('addRecipient manager=' + JSON.stringify(element))

              if (isThisAlreadyInArray && isThisAlreadyInArray.length === 0) {
                this.recipientsSelected.push({
                  recipient: newRecipient,
                  type: 'management portal user',
                  id: element.accountManagingUserId
                })
              }
            }
          })
        }
      } else {
        this.recipientsSelected.push({
          recipient
        })
      }
    }

    console.debug('recipientsSelected=' + JSON.stringify(this.recipientsSelected))
  },

  openRecipentModal() {
    this.toggleRecipients = true
  },

  addRecipientModal(recipient) {
    if (
      (!this.vendorHoaID || this.vendorHoaID <= 0) &&
      this.vendorHoaList &&
      this.vendorHoaList != undefined &&
      this.vendorHoaList.length > 0
    ) {
      notifyError('Please select an hoa before adding a recipient.')
      return
    }

    if (recipient === 'Other Committee') {
      this.toggleRecipients = false
      this.toggleCommittee = true
    } else if (recipient === 'Vendors') {
      this.toggleRecipients = false
      this.toggleVendor = true
    } else if (recipient === 'Homeowners') {
      this.toggleRecipients = false
      this.toggleHomeowner = true
    } else if (recipient === 'Board of Directors') {
      this.toggleRecipients = false
      this.toggleBoard = true
    } else if (recipient === 'Management Portal Users') {
      this.toggleRecipients = false
      this.toggleManagement = true
    } else {
      this.toggleRecipients = false
      this.addRecipient(recipient)
    }
  },

  async resetPersistedArrays() {
    await this.$store.dispatch('conversation/saveOtherCommitteeID', {
      committeeID: 0
    })

    await this.$store.dispatch('conversation/saveVendorIDs', {
      vendorIds: []
    })

    await this.$store.dispatch('conversation/saveOwnerIDs', {
      ownerIds: []
    })

    await this.$store.dispatch('conversation/saveBoardCommitteeID', {
      committeeID: 0
    })

    await this.$store.dispatch('conversation/saveAccountManagerIDs', {
      accountManagerIds: []
    })
  },

  async eraseRecipient({ recipient }) {
    const recipientsSelected = this.recipientsSelected
    if (recipientsSelected !== undefined && recipientsSelected) {
      for (let a = 0; a < recipientsSelected.length; a++) {
        const { recipient: _recipient } = recipientsSelected[a]

        let tempIds = []

        if (_recipient === recipient.recipient) {
          this.recipientsSelected.splice(a, 1)

          if (_recipient === 'Other Committee') {
            await this.$store.dispatch('conversation/saveOtherCommitteeID', {
              committeeID: 0
            })
          } else if (_recipient.indexOf('Vendor') !== -1) {
            Array.from(this.recipientsSelected).forEach(f => {
              if (f && f.id) {
                tempIds.push(f.id)
              }
            })

            await this.$store.dispatch('conversation/saveVendorIDs', {
              vendorIds: tempIds
            })
          } else if (_recipient.indexOf('VendorRecipient') !== -1) {
            Array.from(this.recipientsSelected).forEach(f => {
              if (f && f.id) {
                tempIds.push(f.id)
              }
            })

            await this.$store.dispatch('conversation/saveVendorRequestIDs', {
              vendorRequestIds: tempIds
            })
          } else if (_recipient === 'Board of Directors') {
            await this.$store.dispatch('conversation/saveBoardCommitteeID', {
              committeeID: 0
            })
          } else if (_recipient.indexOf('Management Portal User') !== -1) {
            if (this.isDebug === true)
              console.debug('Management=' + JSON.stringify(this.recipientsSelected))

            Array.from(this.recipientsSelected).forEach(f => {
              if (f && f.id) {
                tempIds.push(f.id)
              }
            })

            await this.$store.dispatch('conversation/saveAccountManagerIDs', {
              accountManagerIds: tempIds
            })
          }

          break
        }
      }
    }
  },

  async deleteDropFile(index) {
    this.files.splice(index, 1)

    const attachments = this.files

    const previews = this.previews
    this.previews.splice(index, 1)

    this.$store.dispatch('conversation/modify', {
      previews,
      attachments
    })
  },

  async updateAttachments() {
    const attachments = this.files
    if (!Array.isArray(attachments)) {
      notifyProblem('A problem occurred and the attachments could not be uploaded.')
      return
    }

    ////////////////////////////////
    //  Store previews
    ////////////////////////////////

    this.previews = this.files.map(file => {
      return URL.createObjectURL(file)
    })

    this.$store.dispatch('conversation/modify', {
      previews: this.previews,
      attachments
    })
  },

  async reload() {
    this.loading = true

    console.debug('reload new message route params=' + JSON.stringify(this.$route.params))

    this.filterBy = ''

    await this.resetPersistedArrays()

    if (this.isDebug === true) console.debug('custom params=' + JSON.stringify(this.$route.params))

    if (
      this.$route.params.apinvoiceid &&
      this.$route.params.apinvoiceid != undefined &&
      this.$route.params.apinvoicepayeeid &&
      this.$route.params.apinvoicepayeeid != undefined &&
      this.$route.params.apinvoicelabel &&
      this.$route.params.apinvoicelabel != undefined
    ) {
      this.apInvoiceID = this.$route.params.apinvoiceid
      this.filterBy = 'ApInvoice'
      this.addValue = this.$route.params.apinvoicepayeeid
      this.addLabel = this.$route.params.apinvoicelabel

      if (this.isDebug === true)
        console.debug('addValue=' + this.addValue + ', addLabel=' + this.addLabel)
    } else if (
      this.$route.params.escrowid &&
      this.$route.params.escrowid != undefined &&
      this.$route.params.escrowownerid &&
      this.$route.params.escrowownerid != undefined &&
      this.$route.params.escrowlabel &&
      this.$route.params.escrowlabel != undefined
    ) {
      this.escrowID = this.$route.params.escrowid
      this.filterBy = 'Escrow'
      this.addValue = this.$route.params.escrowownerid
      this.addLabel = this.$route.params.escrowlabel

      if (this.isDebug === true) console.debug('escrowID=' + this.escrowID)
    } else if (
      this.$route.params.suggestionid &&
      this.$route.params.suggestionid != undefined &&
      this.$route.params.suggestionownerid &&
      this.$route.params.suggestionownerid != undefined &&
      this.$route.params.suggestionlabel &&
      this.$route.params.suggestionlabel != undefined
    ) {
      this.suggestionID = this.$route.params.suggestionid
      this.filterBy = 'Suggestion'
      this.addValue = this.$route.params.suggestionownerid
      this.addLabel = this.$route.params.suggestionlabel

      if (this.isDebug === true) console.debug('suggestionid=' + this.suggestionid)
    } else if (
      this.$route.params.violationid &&
      this.$route.params.violationid != undefined &&
      this.$route.params.violationownerid &&
      this.$route.params.violationownerid != undefined &&
      this.$route.params.violationlabel &&
      this.$route.params.violationlabel != undefined
    ) {
      this.violationID = this.$route.params.violationid
      this.filterBy = 'Violation'
      this.addValue = this.$route.params.violationownerid
      this.addLabel = this.$route.params.violationlabel

      const recipient = this.addLabel
      this.recipientsSelected.push({
        recipient
      })

      if (this.isDebug === true) console.debug('violationID=' + this.violationID)
    } else if (
      this.$route.params.vendorrequestid &&
      this.$route.params.vendorrequestid != undefined &&
      //this.$route.params.vendorrequestownerid &&
      //this.$route.params.vendorrequestownerid != undefined &&
      this.$route.params.vendorrequestlabel &&
      this.$route.params.vendorrequestlabel != undefined
    ) {
      if (this.isDebug === true)
        console.debug('Vendor Request=' + this.$route.params.vendorrequestid)
      this.vendorRequestID = this.$route.params.vendorrequestid
      this.addValue = this.$route.params.vendorrequestownerid
      this.addLabel = this.$route.params.vendorrequestlabel
      this.filterBy = 'Vendor Request'

      if (this.isDebug === true)
        console.debug(
          'vendorRequestID=' + this.vendorRequestID + ',' + this.addValue + ',' + this.addLabel
        )
    } else if (
      this.$route.params.workorderid &&
      this.$route.params.workorderid != undefined &&
      this.$route.params.workordervendorid &&
      this.$route.params.workordervendorid != undefined &&
      this.$route.params.workorderlabel &&
      this.$route.params.workorderlabel != undefined
    ) {
      this.workOrderID = this.$route.params.workorderid
      this.filterBy = 'Work Order'
      this.addValue = this.$route.params.workordervendorid
      this.addLabel = this.$route.params.workorderlabel

      if (this.$route.params.workorderhoaid && this.$route.params.workorderhoaid != undefined) {
        //pre-select the vendorHoaID
        this.vendorHoaList = []
        this.vendorHoaID = this.$route.params.workorderhoaid
        this.fromAskaQuestion = true
      }

      if (this.isDebug === true) console.debug('workOrderID=' + this.workOrderID)
    } else if (
      this.$route.params.workorderrequestid &&
      this.$route.params.workorderrequestid != undefined &&
      this.$route.params.submitterid &&
      this.$route.params.submitterid != undefined &&
      this.$route.params.submitterlabel &&
      this.$route.params.submitterlabel != undefined
    ) {
      if (this.isDebug === true)
        console.debug('Work Order Request=' + this.$route.params.workorderrequestid)
      this.workOrderRequestID = this.$route.params.workorderrequestid
      this.addValue = this.$route.params.submitterid
      this.addLabel = this.$route.params.submitterlabel
      this.filterBy = 'Work Order Request'

      if (this.isDebug === true)
        console.debug(
          'workOrderRequestID=' +
            this.workOrderRequestID +
            ',' +
            this.addValue +
            ',' +
            this.addLabel
        )
    } else if (
      this.$route.params.architecturalsubmissionid &&
      this.$route.params.architecturalsubmissionid != undefined &&
      this.$route.params.archownerid &&
      this.$route.params.archownerid != undefined &&
      this.$route.params.archlabel &&
      this.$route.params.archlabel != undefined
    ) {
      this.architecturalSubmissionID = this.$route.params.architecturalsubmissionid
      this.filterBy = 'Architectural Request'
      this.addValue = this.$route.params.archownerid
      this.addLabel = this.$route.params.archlabel

      const recipient = this.addLabel
      this.recipientsSelected.push({
        recipient
      })

      console.debug('architecturalSubmissionID=' + this.architecturalSubmissionID)
    } else if (this.$route.params.amenityid && this.$route.params.amenityid != undefined) {
      this.amenityID = this.$route.params.amenityid
      this.filterBy = 'Amenity'

      if (
        this.$route.params.amenityownerid &&
        this.$route.params.amenityownerid != undefined &&
        this.$route.params.amenitylabel &&
        this.$route.params.amenitylabel != undefined
      ) {
        this.addValue = this.$route.params.amenityownerid
        this.addLabel = this.$route.params.amenitylabel
      }

      if (this.isDebug === true) console.debug('amenityID=' + this.amenityID)
    }

    this.getSubjectsDropDown()

    if (typeof this.authUser.vendorGlobalID === 'number') {
      this.getVendorHoaDropDown()
    }

    this.loading = false
  },

  async getSubjectsDropDown() {
    if (this.isDebug == true) console.debug('in getSubjectsDropDown()...')
    await conversationList.dispatch('loadHardcodedMessageSubjects').then(({ list }) => {
      if (list) {
        this.subjectList = list
      }
    })
  },

  async getVendorHoaDropDown() {
    if (this.isDebug == true) console.debug('in getVendorHoaDropDown()...')
    await conversationList
      .dispatch('getVendorHoaList', {
        vendorGlobalID: this.authUser.vendorGlobalID
      })
      .then(({ list }) => {
        if (list) {
          this.vendorHoaList = list
        }
      })
  },

  open() {
    this.subject = ''
    this.isOpen = true
    this.loading = false
  },

  async processSubmit() {
    if (
      !this.recipientsSelected ||
      this.recipientsSelected == undefined ||
      this.recipientsSelected.length === 0
    ) {
      notifyError('At least one recipient is required.')
      return
    }

    if (
      (!this.vendorHoaID || this.vendorHoaID <= 0) &&
      this.vendorHoaList &&
      this.vendorHoaList != undefined &&
      this.vendorHoaList.length > 0
    ) {
      notifyError('Please select an hoa before adding a recipient.')
      return
    }

    if (!this.message || this.message == undefined || !this.subject || this.subject == undefined) {
      notifyError('Please enter a subject and message.')
    }

    if (this.message.length >= 8000) {
      notifyError('Please shorten your message down to below 8,000 characters.')
    }

    const recipientsSelected = this.recipientsSelected

    let payload = {
      hoaID: this.hoaId && this.hoaId > 0 ? this.hoaId : this.vendorHoaID,
      messageText: this.message,
      subject: this.subject
    }

    for (let a = 0; a < recipientsSelected.length; a++) {
      const { recipient: recipient } = recipientsSelected[a]

      if (recipient === 'Other Committee') {
        payload.otherCommitteeID = this.selectedOtherCommitteeID
      } else if (recipient.indexOf('Vendor') !== -1) {
        payload.vendorIds = this.selectedVendorIDs
      } else if (recipient.indexOf('Homeowner') !== -1) {
        payload.homeOwnerIds = this.selectedOwnerIDs
      } else if (recipient === 'Board of Directors') {
        payload.boardCommitteeID = this.selectedBoardCommitteeID
      } else if (recipient.indexOf('Management Portal User') !== -1) {
        payload.accountManagingUserIds = this.selectedAccountManagerIDS
      } else if (recipient === 'Accountant') {
        payload.isAccountantRecipient = true
      } else if (recipient === 'HoaMyWay Administrative Team') {
        payload.areAdminRecipients = true
      }
    }

    //Process any reference ids
    if (this.apInvoiceID && this.apInvoiceID != undefined) {
      payload.genericReferenceID = this.apInvoiceID
      payload.genericReferenceIdName = 'APInvoiceID'
    } else if (this.suggestionID && this.suggestionID != undefined) {
      payload.genericReferenceID = this.suggestionID
      payload.genericReferenceIdName = 'SuggestionID'
    } else if (this.escrowID && this.escrowID != undefined) {
      payload.genericReferenceID = this.escrowID
      payload.genericReferenceIdName = 'EscrowID'
    } else if (this.violationID && this.violationID != undefined) {
      payload.genericReferenceID = this.violationID
      payload.genericReferenceIdName = 'ViolationID'
    } else if (this.workOrderID && this.workOrderID != undefined) {
      payload.genericReferenceID = this.workOrderID
      payload.genericReferenceIdName = 'WorkOrderID'
    } else if (this.vendorRequestID && this.vendorRequestID != undefined) {
      payload.genericReferenceID = this.vendorRequestID
      payload.genericReferenceIdName = 'VendorRequestID'
    } else if (this.workOrderRequestID && this.workOrderRequestID != undefined) {
      payload.genericReferenceID = this.workOrderRequestID
      payload.genericReferenceIdName = 'WorkOrderRequestID'
    } else if (this.architecturalSubmissionID && this.architecturalSubmissionID != undefined) {
      payload.genericReferenceID = this.architecturalSubmissionID
      payload.genericReferenceIdName = 'ArchitecturalSubmissionID'
    } else if (this.amenityID && this.amenityID != undefined) {
      payload.genericReferenceID = this.amenityID
      payload.genericReferenceIdName = 'AmenityID'
    }

    if (this.isDebug === true) console.debug('payload=' + JSON.stringify(payload))

    await conversationList.dispatch('addPrivateThreadWithMessageAndParticipant', {
      hoaID: this.hoaId && this.hoaId > 0 ? this.hoaId : this.vendorHoaID,
      vendorGlobalID: _get(this.authUser, 'vendorGlobalID', null),
      subject: this.subject ? this.subject : '',
      messageText: this.message ? this.message : '',
      otherCommitteeID: payload.otherCommitteeID ? payload.otherCommitteeID : null,
      vendorIds: payload.vendorIds && payload.vendorIds.length > 0 ? payload.vendorIds : null,
      boardCommitteeID: payload.boardCommitteeID ? payload.boardCommitteeID : null,
      isAccountantRecipient: payload.isAccountantRecipient ? payload.isAccountantRecipient : null,
      areAdminRecipients: payload.areAdminRecipients ? payload.areAdminRecipients : null,
      homeOwnerIds:
        payload.homeOwnerIds && payload.homeOwnerIds.length > 0 ? payload.homeOwnerIds : null,
      accountManagingUserIds:
        payload.accountManagingUserIds && payload.accountManagingUserIds.length > 0
          ? payload.accountManagingUserIds
          : null,
      genericReferenceID: payload.genericReferenceID ? payload.genericReferenceID : null,
      genericReferenceIdName: payload.genericReferenceIdName
        ? payload.genericReferenceIdName
        : null,
      genericAddID: this.addValue ? this.addValue : null,
      genericAddName: this.addLabel ? this.addLabel : null,
      done: async ({ details }) => {
        if (this.isDebug === true) console.debug('done details=' + JSON.stringify(details))

        if (
          details &&
          details.privateThreadMessageID &&
          details.privateThreadMessageID != undefined
        ) {
          this.saveAttachments(details.privateThreadMessageID)
        } else {
          notifyError('There was a problem creating your new message.')
        }
      }
    })
  },

  async saveAttachments(id) {
    //Process one or more attachments
    try {
      if (this.isDebug === true) console.debug('files=' + this.file)

      if (this.files && this.files.length > 0) {
        for (let a = 0; a < this.files.length; a++) {
          await conversationAttachmentStore
            .dispatch('addConversationAttachment', {
              privateThreadMessageID: id
            })
            .then(async ({ returned }) => {
              if (
                returned &&
                returned != undefined &&
                returned.privateThreadMessageAttachmentID > 0
              ) {
                const payload = {
                  privateThreadMessageAttachmentID: returned.privateThreadMessageAttachmentID,
                  file: this.files[a]
                }

                await conversationAttachmentStore
                  .dispatch('uploadConversationAttachment', {
                    payload: payload
                  })
                  .then(({ returnedfile }) => {
                    this.base64pdf = ''
                    if (returnedfile && returnedfile != undefined) {
                      notifyMessage(
                        'Successfully created the new conversation message with attachments.'
                      )
                      this.returnToParent()
                    } else {
                      notifyError('There was a problem uploading your attachments.')
                      this.returnToParent()
                    }
                  })
              }
            })
        }
      } else {
        notifyMessage('Successfully created the new conversation message.')
        this.returnToParent()
      }
    } catch (e) {
      notifyError(e)
    }
  },

  returnToParent() {
    if (this.violationID) {
      let path = `/violationtabs/${this.violationID}/violationConversations#tab`

      this.$router.push({
        path: path,
        params: { id: this.violationID }
      })
    } else if (this.suggestionID) {
      let path = `/communityCenter/suggestions#tab`

      this.$router.push({
        path: path
      })
    } else if (this.escrowID) {
      let path = `/escrowTabs/${this.escrowID}/conversation/escrowConversations#tab`

      this.$router.push({
        path: path
      })
    } else if (this.apInvoiceID) {
      this.$router.push({
        path: `/invoicemessages/${this.apInvoiceID}#tab`
      })
    } else if (this.workOrderID) {
      this.$router.push({
        path: `/workordertabs/${this.workOrderID}/workorderConversations#tab`
      })
    } else if (this.workOrderRequestID) {
      this.$router.push({
        path: `/workorderpromotetabs/${this.workOrderRequestID}/workorderRequestConversation#tab`,
        params: {
          id: this.workOrderRequestID
        }
      })
    } else if (this.vendorRequestID) {
      this.$router.push({
        path: `/vendorRequests/${this.vendorRequestID}/vendorRequestConversations#tab`,
        params: {
          id: this.vendorRequestID
        }
      })
    } else if (this.architecturalSubmissionID) {
      this.$router.push({
        path: `/architecturaltabs/${this.architecturalSubmissionID}/architecturalRequestConversations#tab`
      })
    } else if (this.amenityID) {
      this.$router.push({
        path: `/amenityconversations/${this.amenityID}`
      })
    } else {
      if (this.$route.path !== '/conversations') {
        this.$router.push('/conversations?isglobal=true')
      }
    }
  },

  closeModal() {
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
  }
}
