<template>
  <div message-modal>
    <div class="is-size-4" v-if="vendorList">
      Vendor
    </div>
    <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(processSubmit)">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <div class="box list-content column is-12">
                  <b-field label="Search">
                    <b-input @input="filterList" v-model="filterBy" />
                  </b-field>
                  <b-table
                    :data="vendorList"
                    :loading="loading"
                    :striped="true"
                    :sticky-header="true"
                    :checked-rows.sync="checkedRows"
                    checkable
                    :checkbox-position="checkboxPosition"
                    :style="{ maxHeight: '400px' }"
                  >
                    <template slot="empty">
                      <empty-list v-if="!loading" />
                    </template>
                    <b-table-column label="User Name" field="name" sortable v-slot="props"
                      ><span
                        v-if="
                          props.row.name && props.row.name != undefined && props.row.name.length > 0
                        "
                        >{{ props.row.name || '' }}</span
                      >
                      <span
                        v-else-if="
                          props.row.doingBusinessAs &&
                            props.row.doingBusinessAs != undefined &&
                            props.row.doingBusinessAs.length > 0
                        "
                        >{{ props.row.doingBusinessAs || '' }}</span
                      >
                      <span v-else>&nbsp;</span>
                    </b-table-column>
                  </b-table>
                </div>
                <div class="pt-5">
                  <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                    Select
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import EmptyList from '@/components/EmptyList'

export default {
  props: {
    VendorHoaID: Number
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    EmptyList
  },

  mounted() {
    this.reload()
    this.vendorID = 0
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
