import { conversationList } from '@/services/Conversations/store'
import { notifyError } from '@/services/notify'
import $ from 'jquery'

export const methods = {
  async reload() {
    this.loading = true

    this.$store.dispatch('conversation/saveOwnerIDs', {
      ownerIds: []
    })

    this.getHomeowners()

    this.loading = false
  },

  async getHomeowners() {
    if (this.isDebug == true) console.debug('in getHomeowners()...')
    await conversationList
      .dispatch('loadHomeowners', {
        hoaID: this.hoaId && this.hoaId > 0 ? this.hoaId : this.VendorHoaID
      })
      .then(({ list }) => {
        if (list) {
          this.homeownerList = list
          this.homeownerListCopy = list
        }
      })
  },

  filterList() {
    if (this.isDebug === true) console.debug('in filterList...')
    if (this.filterBy && this.homeownerList) {
      if (this.filterBy.length === 0) {
        this.homeownerList = this.homeownerListCopy
      } else {
        this.homeownerList = this.homeownerList.filter(
          f =>
            f.primaryOwner.name.indexOf(this.filterBy) !== -1 ||
            f.email.indexOf(this.filterBy) !== -1
        )
      }
    } else {
      this.homeownerList = this.homeownerListCopy
    }
  },

  open() {
    this.ownerID = 0
    this.isOpen = true
    this.loading = false
  },

  async processSubmit() {
    if (!this.checkedRows || this.checkedRows == undefined || this.checkedRows.length === 0) {
      notifyError('At least one homeowner is required.')
      return
    }

    Array.from(this.checkedRows).forEach(element => {
      if (element) {
        if (this.isDebug === true) console.debug('element' + JSON.stringify(element))

        if (
          element.primaryOwner &&
          element.primaryOwner != undefined &&
          element.primaryOwner.ownerID
        ) {
          this.ownerIds.push(element.primaryOwner.ownerID)
          this.ownerIdsJson.push({
            name: element.primaryOwner.name,
            ownerID: element.primaryOwner.ownerID
          })
        }
      }
    })

    if (this.isDebug === true) console.debug('ownerIds=' + JSON.stringify(this.ownerIds))

    this.$store.dispatch('conversation/saveOwnerIDs', {
      ownerIds: this.ownerIds,
      ownerIdsJson: this.ownerIdsJson
    })

    this.closeModal()
  },

  closeModal() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggleHomeowner = false))
    $('#clickclose').click()
  }
}
