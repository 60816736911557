/*
  import { conversationAttachmentStore } from '@/services/Conversations/ConversationAttachment/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem, notifyMessage, notifyError } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = false

/* eslint-disable no-empty-pattern */
const actions = {
  async getConversationAttachmentsById({}, { privateThreadMessageID }) {
    if (isDebug === true) console.debug('in getConversationAttachmentsById...')
    try {
      const result = await kms.get('/Messaging/PrivateThreadMessageAttachment/List', {
        params: {
          privateThreadMessageID
        }
      })

      if (isDebug == true)
        console.debug('getConversationAttachmentsById log=' + JSON.stringify(result.results))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The attachments list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getConversationGetFile({}, { params = {} }) {
    try {
      const result = await kms.get(`/Messaging/PrivateThreadMessageAttachment/GetAttachmentFile`, {
        params
      })

      if (isDebug == true) console.debug('getConversationGetFile=' + result)

      return {
        returned: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the conversation attachment file.`)
      console.error(exception)
    }

    return {
      returned: null
    }
  },

  async updateConversationAttachment({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Messaging/PrivateThreadMessageAttachment`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.privateThreadMessageAttachmentID > 0) {
        if (payload.remove && payload.reomove != undefined && payload.remove == true) {
          notifyMessage(`Successfully removed the conversation attachment.`)
        } else {
          notifyMessage(`Successfully updated the conversation attachment.`)
        }
        done()
      } else {
        notifyError('There was a problem updating this conversation attachment.')
      }
    } catch (exception) {
      notifyProblem('There was a problem updating this conversation attachment.')
      console.error(exception)
    }
  },

  async addConversationAttachment({}, { privateThreadMessageID, documentID }) {
    try {
      const privateThreadMessageAttachment = await kms.post(
        `/Messaging/PrivateThreadMessageAttachment`,
        {
          privateThreadMessageID,
          documentID
        }
      )

      if (isDebug == true)
        console.debug('addConversationPhoto=' + JSON.stringify(privateThreadMessageAttachment))

      return {
        returned: privateThreadMessageAttachment
      }
    } catch (exception) {
      notifyProblem('There was a problem adding the conversation attachment data.')
      console.error(exception)
    }

    return {
      returned: null
    }
  },

  async uploadConversationAttachment({}, { payload }) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/Messaging/PrivateThreadMessageAttachment/StoreAttachment?privateThreadMessageAttachmentID=${payload.privateThreadMessageAttachmentID}`
      const uploadedFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug('uploadConversationAttachment with payload=' + JSON.stringify(uploadedFile))

      return {
        returnedfile: uploadedFile
      }
    } catch (exception) {
      notifyProblem('There was a problem uploading the conversation attachment.')
      console.error(exception)
    }

    return {
      returnedfile: null
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const conversationAttachmentStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
