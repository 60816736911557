/*
  import { conversationList } from '@/services/Conversations/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem, notifyError } from '@/services/notify'
import kms from '@/services/kms'
import uniqBy from 'lodash/uniqBy'

import _get from 'lodash/get'
import parseAddress from '@/utilities/address/parse'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await conversationList.dispatch ('getPrivateThreadList', {
      hoaId
    });
  */

  async loadHardcodedMessageSubjects({}) {
    try {
      const myList = [
        {
          value: 'Ask Your Accountant',
          label: 'Ask Your Accountant'
        },
        {
          value: 'Ask a Board Member',
          label: 'Ask a Board Member'
        }
      ]

      if (myList) {
        myList.unshift({
          value: '',
          label: '<-Select a Subject->'
        })
      }

      return {
        list: myList
      }
    } catch (exception) {
      notifyProblem(`The Message Subjects could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async loadConversationRecipients({}) {
    try {
      const myList = [
        {
          value: 'Board of Directors',
          label: 'Add Board of Directors'
        },
        {
          value: 'Other Committee',
          label: 'Add Other Committee'
        },
        {
          value: 'Accountant',
          label: 'Add Accountant'
        },
        {
          value: 'Vendors',
          label: 'Add Vendors'
        },
        {
          value: 'Homeowners',
          label: 'Add Homeowners'
        },
        {
          value: 'Management Portal Users',
          label: 'Add Management Portal Users'
        },
        {
          value: 'HoaMyWay Administrative Team',
          label: 'Add HoaMyWay Administrative Team'
        }
      ]

      return {
        list: myList
      }
    } catch (exception) {
      notifyProblem(`The Conversation Recipients could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async loadCommittees({}, { hoaID, isBoardMember }) {
    console.log('in loadCommittees...')
    try {
      const result = await kms.get('/Committees/Committee/List', {
        params: {
          hoaID
        }
      })

      let filtered = []
      if (result.results) {
        if (isBoardMember && isBoardMember === true) {
          filtered = result.results.filter(f => f.isBoardMember && f.isBoardMember === true)
        } else {
          filtered = result.results.filter(f => f.isBoardMember !== true)
        }
      }

      if (isBoardMember === true) {
        filtered.unshift({ committeeID: 0, name: '<-Select a Board of Directors Committee->' })
      } else {
        filtered.unshift({ committeeID: 0, name: '<-Select a Committee->' })
      }

      if (isDebug == true) console.debug('loadCommittees=' + JSON.stringify(filtered))

      return {
        list: filtered
      }
    } catch (exception) {
      notifyProblem(`The Committee list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async loadVendors({}, { hoaID }) {
    console.log('in loadVendors...')
    try {
      const result = await kms.get('/Vendors/Vendor/List', {
        params: {
          hoaID
        }
      })

      let filtered = []
      filtered = result.results
      filtered = filtered.filter(f => f.name != '' && f.doingBusinessAs !== '')
      filtered = uniqBy(filtered, 'vendorID')

      if (isDebug == true) console.debug('loadVendors=' + JSON.stringify(filtered))

      return {
        list: filtered
      }
    } catch (exception) {
      notifyProblem(`The Vendor list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async loadHomeowners({}, { hoaID }) {
    console.log('in loadHomeowners...')
    try {
      const { results: units } = await kms.get('/Roster/Unit/List', {
        params: {
          hoaID,
          includeOwnerContacts: true,
          startRecord: 1,
          recordCount: 10000
        }
      })

      let filtered = []
      filtered = units.map(m => ({
        ...m,
        email: '',
        address: parseAddress({
          address: _get(m, 'address', '')
        })
      }))
      filtered = filtered.filter(f => f.primaryOwner && f.primaryOwner.name !== '')

      Array.from(filtered).forEach(f => {
        if (f) {
          f.email = ''

          const ownerContactList = f.primaryOwner.ownerContacts

          if (ownerContactList) {
            if (this.isDebug === true)
              console.debug('ownerContactList=' + JSON.stringify(ownerContactList))

            if (this.isDebug === true)
              console.debug('ownerContactList=' + JSON.stringify(ownerContactList))
            Array.from(ownerContactList).forEach(o => {
              if (o) {
                const contactName = `${o.firstName} ${o.lastName}`

                if (isDebug === true) console.debug('contactName=' + contactName)
                const fName = f.primaryOwner.name

                if (fName === contactName) {
                  f.email = o.emailAddressOne
                }
              }
            })
          }
        }
      })

      if (isDebug == true) console.debug('loadHomeowners=' + JSON.stringify(filtered))

      return {
        list: filtered
      }
    } catch (exception) {
      notifyProblem(`The Home Owner list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getMessageTypes({}, { hoaId, vendorGlobalID, showOnlyHomeownerThreads }) {
    console.log('in getMessageTypes...')
    try {
      const result = await kms.get('/Messaging/PrivateThread/List', {
        params: {
          hoaId,
          vendorGlobalID,
          showOnlyHomeownerThreads
        }
      })

      let mapped = []
      if (result.results) {
        mapped = result.results
          .map(v => ({
            value:
              v.privateThreadReferences[0] &&
              v.privateThreadReferences[0].privateThreadReferenceID &&
              v.privateThreadReferences[0].privateThreadReferenceID != undefined
                ? v.privateThreadReferences[0].privateThreadReferenceID
                : 0,
            label: v.referenceType ? v.referenceType : ''
          }))

          .filter(f => f.label.length > 0 && f.value > 0)
          .sort((a, b) => a.label - b.label)
      }

      mapped.unshift({ value: '', label: '<-Select Message Type->' })
      const uniqMapped = uniqBy(mapped, 'label')

      if (isDebug == true) console.debug('getMessageTypes log=' + JSON.stringify(uniqMapped))

      return {
        list: uniqMapped
      }
    } catch (exception) {
      notifyProblem(`The message type list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getMessageSubjects({}, { hoaId, vendorGlobalID, showOnlyHomeownerThreads }) {
    console.log('in getMessageTypes...')
    try {
      const result = await kms.get('/Messaging/PrivateThread/List', {
        params: {
          hoaId,
          vendorGlobalID,
          showOnlyHomeownerThreads
        }
      })

      let mapped = []
      if (result.results) {
        mapped = result.results
          .map(v => ({
            value: v.privateThreadID ? v.privateThreadID : 0,
            label: v.subject ? v.subject : ''
          }))

          .filter(f => f.label.length > 0 && f.value > 0)
          .sort((a, b) => a.label - b.label)
      }

      mapped.unshift({ value: '', label: '<-Select Message Subject->' })
      const uniqMapped = uniqBy(mapped, 'label')

      if (isDebug == true) console.debug('getMessageSubjects log=' + JSON.stringify(uniqMapped))

      return {
        list: uniqMapped
      }
    } catch (exception) {
      notifyProblem(`The message subject list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getPrivateThreadList(
    {},
    { hoaId, vendorGlobalID, showOnlyHomeownerThreads, referenceType, referenceID }
  ) {
    console.log('in getPrivateThreadList...')
    try {
      const result = await kms.get('/Messaging/PrivateThread/List', {
        params: {
          hoaId,
          vendorGlobalID,
          showOnlyHomeownerThreads,
          referenceType,
          referenceID
        }
      })

      if (isDebug == true) console.debug('getPrivateThreadList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The private thread list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getVendorHoaList({}, { vendorGlobalID }) {
    console.log('in getVendorHoaList...')
    try {
      const result = await kms.get('/Vendors/VendorGlobal/HoaListByVendorGlobal', {
        params: {
          vendorGlobalID
        }
      })

      let filtered = result.results.map(m => ({
        label: m.name,
        value: m.hoaID
      }))

      filtered.unshift({ value: '', label: '<-Select Hoa->' })

      if (isDebug == true) console.debug('getVendorHoaList log=' + JSON.stringify(filtered))

      return {
        list: filtered
      }
    } catch (exception) {
      notifyProblem(`The Vendor Hoa list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async addPrivateThreadWithMessageAndParticipant(
    { dispatch },
    {
      hoaID,
      vendorGlobalID,
      subject,
      messageText,
      otherCommitteeID,
      vendorIds,
      boardCommitteeID,
      isAccountantRecipient,
      areAdminRecipients,
      homeOwnerIds,
      accountManagingUserIds,
      genericReferenceID,
      genericReferenceIdName,
      genericAddID,
      genericAddName,
      done
    }
  ) {
    if (isDebug == true) console.debug('...in addPrivateThreadWithMessageAndParticipant')
    try {
      const results = await kms.post(`/Messaging/PrivateThread/CreatePrivateThreadWithMessage`, {
        hoaID,
        vendorGlobalID,
        subject,
        messageText,
        otherCommitteeID,
        vendorIds,
        boardCommitteeID,
        isAccountantRecipient,
        areAdminRecipients,
        homeOwnerIds,
        accountManagingUserIds,
        genericReferenceID,
        genericReferenceIdName,
        genericAddID,
        genericAddName
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.privateThreadMessageID > 0) {
        console.debug(`Successfully created a new conversation thread.`)
        done({ details: results })
      } else {
        notifyError('There was a problem creating a new conversation.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem creating a new conversation.`)
      console.error(exception)
    }
  },

  async addParticipantsToPrivateThreadMessage(
    { dispatch },
    {
      privateThreadID,
      vendorGlobalID,
      boardCommitteeID,
      otherCommitteeID,
      vendorIds,
      homeOwnerIds,
      accountManagingUserIds,
      isAccountantRecipient,
      areAdminRecipients,
      done
    }
  ) {
    if (isDebug == true) console.debug('...in addParticipantsToPrivateThreadMessage')
    try {
      const results = await kms.post(
        `/Messaging/PrivateThread/AddParticipantsToPrivateThreadMessage`,
        {
          privateThreadID,
          vendorGlobalID,
          boardCommitteeID,
          otherCommitteeID,
          vendorIds,
          homeOwnerIds,
          accountManagingUserIds,
          isAccountantRecipient,
          areAdminRecipients
        }
      )

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        console.debug(`Successfully created added participants to the conversation thread.`)
        done({ details: results })
      } else {
        notifyError('There was a problem adding participants to this conversation.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding participants to this conversation.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const conversationList = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
