<template>
  <div message-modal>
    <div class="is-size-4" v-if="recipientList !== undefined && recipientList">
      Add Participants
    </div>
    <div v-if="!friendlyMessage">
      <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(confirmAddNewRecipients)">
            <fieldset>
              <div class="form-grid">
                <div class="column is-12">
                  <label class="label">Recipients</label>
                  <div>
                    <div
                      v-if="
                        typeof recipientsSelected !== 'undefined' &&
                          recipientsSelected !== undefined &&
                          recipientsSelected
                      "
                      :style="
                        `
                        display: inline;
                      `
                      "
                    >
                      <div
                        v-if="
                          recipientsSelected !== undefined &&
                            recipientsSelected &&
                            recipientsSelected.length === 0
                        "
                      >
                        <span style="font-style:italic">No recipients added</span>
                      </div>
                      <div
                        class="pt-3"
                        v-for="(recipient, index) in recipientsSelected"
                        :key="index"
                        :style="{
                          verticalAlign: 'top',
                          marginLeft: '0px'
                        }"
                      >
                        <b-taglist attached>
                          <b-tag
                            type="is-light"
                            style="background-color: white !important;"
                            closable
                            @close="eraseRecipient({ recipient })"
                            >{{ recipient.recipient }}</b-tag
                          >
                        </b-taglist>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-12">
                  <div
                    class="column is-12"
                    v-if="vendorHoaList && vendorHoaList != undefined && vendorHoaList.length > 0"
                  >
                    <label class="label">Vendor Hoas</label>
                    <valid-select
                      placeholder="Vendor Hoas"
                      vid="vendorHoaID"
                      v-model="vendorHoaID"
                      rules="required"
                      aria-required="Please select an Hoa"
                    >
                      <option
                        v-for="option in vendorHoaList"
                        :value="option.value"
                        :key="option.value"
                        >{{ option.label }}</option
                      >
                    </valid-select>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>
    <div>
      <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(processSubmit)">
            <fieldset>
              <div class="form-grid">
                <div class="column is-12 pl-3">
                  <label class="label">Recipients</label>
                  <div>
                    <div :style="{ display: 'flex', alignItems: 'center' }">
                      <Recipient
                        ref="recipient"
                        :addLabel="addLabel"
                        :addValue="parseInt(addValue)"
                        :filterBy="filterByString"
                        :onChange="onRecipientChange"
                      />
                      <div :style="{ padding: '0 12px' }"></div>
                      <b-button
                        type="is-primary"
                        :style="{ marginLeft: '12px' }"
                        @click.prevent="addRecipientModal($refs.recipient.getValue())"
                        >Add</b-button
                      >
                    </div>
                    <div class="pt-5">
                      <div :style="{ padding: '0 12px' }"></div>

                      <button
                        type="button"
                        @click.prevent="processRecipientsSubmit"
                        :disabled="loading"
                        class="button is-primary is-rounded"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>
    <Modal :toggle.sync="toggleBoard">
      <BoardModal :toggle="toggleBoard" :VendorHoaID="vendorHoaID"> </BoardModal>
    </Modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Recipient from '@/components/inputs/ConversationRecipient'
import BoardModal from '../boardOfDirectors'
import Modal from '@/components/Modal'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  props: {
    recipientList: Array,
    recipientsSelected: Array,
    privateThreadID: Number,
    filterByString: String,
    addValue: Number,
    addLabel: String,
    onRecipientChange: {
      default() {},
      type: Function
    },
    addRecipientModal: Function,
    vendorHoaID: Number
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    Recipient,
    BoardModal,
    Modal
  },

  watch: {
    toggleBoard() {
      if (this.toggleBoard == false) {
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggleBoard = false))
        }

        if (this.isDebug === true)
          console.debug('watched toggle board selected=' + this.selectedBoardCommitteeID)
      }
    }
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser']),
      ...mapGetters('conversation', [
        'selectedVendorIDs',
        'selectedVendorIDsJson',
        'selectedBoardCommitteeID',
        'selectedOtherCommitteeID',
        'selectedOwnerIDs',
        'selectedOwnerIDsJson',
        'selectedAccountManagerIDS',
        'selectedAccountManagerIDsJson'
      ])
    })
  },

  data,
  methods
}
</script>
