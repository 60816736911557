export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    openOnFocus: true,
    homeownerList: [],
    homeownerListCopy: [],
    ownerIds: [],
    ownerIdsJson: [],
    checkboxPosition: 'left',
    checkedRows: [],
    toggleHomeowner: false,
    filterBy: ''
  }
}
