export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    openOnFocus: true,
    committeeList: [],
    committeeID: null,
    toggleCommittee: false
  }
}
