export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    openOnFocus: true,
    subject: '',
    message: '',
    addValue: '',
    addLabel: '',
    apInvoiceID: 0,
    violationID: 0,
    escrowID: 0,
    workOrderID: 0,
    vendorRequestID: 0,
    workOrderRequestID: 0,
    architecturalSubmissionID: 0,
    amenityID: 0,
    subjectList: [],
    vendorHoaList: [],
    vendorHoaID: 0,
    fromAskaQuestion: false,
    recipientsSelected: [],
    toggleCommittee: false,
    toggleVendor: false,
    toggleHomeowner: false,
    toggleBoard: false,
    toggleManagement: false,
    toggleRecipients: false,
    recipientList: [],
    filterBy: '',
    files: [],
    image: null,
    doc: null,
    documentIds: [],
    previews: [],
    docPreviews: []
  }
}
