var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"message-modal":""}},[(_vm.vendorList)?_c('div',{staticClass:"is-size-4"},[_vm._v(" Vendor ")]):_vm._e(),_c('ValidationObserver',{ref:"formGroup",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.processSubmit)}}},[_c('fieldset',[_c('div',{staticClass:"form-grid"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"box list-content column is-12"},[_c('b-field',{attrs:{"label":"Search"}},[_c('b-input',{on:{"input":_vm.filterList},model:{value:(_vm.filterBy),callback:function ($$v) {_vm.filterBy=$$v},expression:"filterBy"}})],1),_c('b-table',{style:({ maxHeight: '400px' }),attrs:{"data":_vm.vendorList,"loading":_vm.loading,"striped":true,"sticky-header":true,"checked-rows":_vm.checkedRows,"checkable":"","checkbox-position":_vm.checkboxPosition},on:{"update:checkedRows":function($event){_vm.checkedRows=$event},"update:checked-rows":function($event){_vm.checkedRows=$event}}},[_c('template',{slot:"empty"},[(!_vm.loading)?_c('empty-list'):_vm._e()],1),_c('b-table-column',{attrs:{"label":"User Name","field":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(
                        props.row.name && props.row.name != undefined && props.row.name.length > 0
                      )?_c('span',[_vm._v(_vm._s(props.row.name || ''))]):(
                        props.row.doingBusinessAs &&
                          props.row.doingBusinessAs != undefined &&
                          props.row.doingBusinessAs.length > 0
                      )?_c('span',[_vm._v(_vm._s(props.row.doingBusinessAs || ''))]):_c('span',[_vm._v(" ")])]}}],null,true)})],2)],1),_c('div',{staticClass:"pt-5"},[_c('button',{staticClass:"button is-primary is-rounded",attrs:{"type":"submit","disabled":_vm.loading}},[_vm._v(" Select ")])])])])]),_c('fieldset',[_c('ValidationProvider',{attrs:{"vid":"providerErrors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }