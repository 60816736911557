<template>
  <PageContentLayoutOnly role="region">
    <div
      v-if="
        !escrowID &&
          !suggestionID &&
          !apInvoiceID &&
          !architecturalSubmissionID &&
          !amenityID &&
          !ownerID
      "
      class="level-left level-item title"
    >
      {{ $t('conversations.title') }}
    </div>
    <div
      v-if="
        !apInvoiceID &&
          !violationID &&
          !workOrderID &&
          !workOrderRequestID &&
          !architecturalSubmissionID &&
          !amenityID &&
          !suggestionID &&
          !escrowID &&
          !vendorRequestID &&
          !ownerID
      "
      class="level-left"
    >
      <b-button @click.prevent="newConversation" size="is-small" type="is-primary" rounded>
        New Conversation
      </b-button>
    </div>

    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rowsOrdered"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
      <template v-slot:header>
        <div v-show="showFilters" class="level-right pb-5 pr-5">
          <b-radio v-model="radio" name="gridFilter" native-value="normal">
            Normal
          </b-radio>
          <b-radio v-model="radio" name="gridFilter" native-value="archived">
            Archived
          </b-radio>
          <b-radio v-model="radio" name="gridFilter" native-value="query">
            Search
          </b-radio>
        </div>
        <div v-show="showFilters" v-if="isQuery && isQuery == true">
          <div class="pb-5">
            <div class="pt=5">
              <b-field>
                <span style="width: 250px" aria-label="Select G/L Expense">
                  <label class="label">Message Type</label>
                  <valid-select
                    placeholder="Message Types"
                    vid="queryMessageType"
                    v-model="queryMessageType"
                  >
                    <option
                      v-for="option in messageTypes"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </span>
                <span class="pl-5">
                  <DatePicker
                    label="Created Dates"
                    ref="fromCreatedDateComponent"
                    ariaPrefix="from"/>&nbsp;&nbsp;&nbsp;&nbsp;
                  <DatePicker
                    label="To"
                    ref="toCreatedDateComponent"
                    ariaLabel="to date"
                    ariaPrefix="to"/></span
                ><span style="padding-top:30px !important;" class="pt-5 pl-5">
                  <b-button
                    class="float-right go-button"
                    size="is-small"
                    type="is-info"
                    rounded
                    @click="processSearch"
                    >Search</b-button
                  ></span
                >
              </b-field>
            </div>
            <div class="pt-5 pb-5">
              <b-field>
                <span style="width: 250px" aria-label="Message Subject">
                  <label class="label">Message Subject</label>
                  <valid-select
                    placeholder="Message Subjects"
                    vid="queryMessageSubject"
                    v-model="queryMessageSubject"
                  >
                    <option
                      v-for="option in messageSubjects"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </span>
                <span class="pl-5">
                  <valid-input
                    name="Author"
                    label="Author"
                    type="text"
                    vid="Author"
                    placeholder="Author"
                    spellcheck="true"
                    aria-label="Author"
                    class="is-small"
                    v-model="queryAuthor"
                  />
                </span>
                <span class="pl-5">
                  <valid-input
                    name="Message"
                    label="Message"
                    type="text"
                    vid="Message"
                    placeholder="Message"
                    spellcheck="true"
                    aria-label="Message"
                    class="is-small"
                    :aria-required="true"
                    v-model="queryMessage"
                  />
                </span>
              </b-field>
            </div>
          </div>
        </div>
      </template>
    </ModernTable>
    <Modal :toggle.sync="toggle">
      <MessageModal :toggle="toggle"> </MessageModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import DatePicker from '@/components/inputs/DatePicker'
import ModernTable from '@/components/tables/Modern/Table'
import { methods } from './keys/methods'
import { data } from './keys/data'
import _ from 'lodash'

import Modal from '@/components/Modal'
import MessageModal from './components/MessageModal'

import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'

export default {
  name: 'Conversations',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    DatePicker,
    ValidSelect,
    ValidInput,
    Modal,
    MessageModal
  },

  props: {
    apInvoiceID: Number,
    violationID: Number,
    workOrderID: Number,
    architecturalSubmissionID: Number,
    amenityID: Number,
    workOrderRequestID: Number,
    suggestionID: Number,
    escrowID: Number,
    vendorRequestID: Number,
    ownerID: Number,
    ownerAuthor: String,
    authenticatedUser: String
  },

  data,

  methods,

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser']),
      rowsOrdered: function() {
        return _.orderBy(this.rows, 'createdDate', 'desc')
      }
    })
  },

  watch: {
    radio: 'reloadRequests',
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    },
    apInvoiceID() {
      this.reload()
    },
    violationID() {
      this.reload()
    },
    amenityID() {
      this.reload()
    },
    architecturalSubmissionID() {
      this.reload()
    },
    workOrderRequestID() {
      this.reload()
    },
    workOrderID() {
      this.reload()
    },
    suggestionID() {
      this.reload()
    },
    escrowID() {
      this.reload()
    },
    ownerID() {
      this.reload()
    },
    vendorRequestID() {
      this.reload()
    },
    isGlobal() {
      this.reload()
    }
  },

  mounted() {
    if (this.$route.query.isglobal) {
      this.showFilters = true
      this.reload()
    }

    if (this.ownerID) {
      this.reload()
    }
  },

  i18n: {
    messages: {
      en: { conversations: { title: 'Conversations' } }
    }
  }
}
</script>

<style></style>
