import { conversationList } from '@/services/Conversations/store'
import { notifyError } from '@/services/notify'
import $ from 'jquery'

export const methods = {
  async reload() {
    this.loading = true

    this.$store.dispatch('conversation/saveVendorID', {
      vendorID: 0
    })

    this.getVendorsDropDown()

    this.loading = false
  },

  async getVendorsDropDown() {
    if (this.isDebug == true) console.debug('in getVendorsDropDown()...')
    await conversationList
      .dispatch('loadVendors', {
        hoaID: this.hoaId && this.hoaId > 0 ? this.hoaId : this.VendorHoaID
      })
      .then(({ list }) => {
        if (list) {
          this.vendorList = list
          this.vendorListCopy = list
        }
      })
  },

  filterList() {
    if (this.isDebug === true) console.debug('in filterList...')
    if (this.filterBy && this.vendorList) {
      if (this.filterBy.length === 0) {
        this.vendorList = this.vendorListCopy
      } else {
        this.vendorList = this.vendorList.filter(f => f.name.indexOf(this.filterBy) !== -1)
      }
    } else {
      this.vendorList = this.vendorListCopy
    }
  },

  open() {
    this.subject = ''
    this.isOpen = true
    this.loading = false
  },

  async processSubmit() {
    if (!this.checkedRows || this.checkedRows == undefined || this.checkedRows.length === 0) {
      notifyError('At least one vendor is required.')
      return
    }

    Array.from(this.checkedRows).forEach(element => {
      if (element) {
        if (this.isDebug === true) console.debug('element' + JSON.stringify(element))

        if (element.name && element.name != undefined && element.vendorID) {
          this.vendorIds.push(element.vendorID)
          this.vendorIdsJson.push({ name: element.name, vendorID: element.vendorID })
        } else if (
          element.doingBusinessAs &&
          element.doingBusinessAs != undefined &&
          element.vendorID
        ) {
          this.vendorIds.push(element.vendorID)
          this.vendorIdsJson.push({ name: element.doingBusinessAs, vendorID: element.vendorID })
        }
      }
    })

    if (this.isDebug === true) console.debug('vendorIds=' + JSON.stringify(this.vendorIds))

    this.$store.dispatch('conversation/saveVendorIDs', {
      vendorIds: this.vendorIds,
      vendorIdsJson: this.vendorIdsJson
    })

    this.closeModal()
  },

  closeModal() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggleVendor = false))
    $('#clickclose').click()
  }
}
