<template>
  <div conversation-component>
    <div class="level pl-1 pr-1">
      <div class="pt-5 level-left level-item title">{{ $t('conversation.title') }}</div>
      <div :style="{ display: 'flex', alignItems: 'center' }">
        <svg
          width="30"
          height="30"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          ></path>
        </svg>
        <router-link class="button primary" :to="{ path: $store.state.route.from.fullPath }"
          >Back</router-link
        >
      </div>
    </div>
    <div>
      <label class="label pl-2"
        >Instructions
        <Info
          title="Steps"
          information="Steps for creating a new conversation"
          imageOnePath="markdown"
          titleOne="Step 1: Type the Text for your Conversation"
          infoOne="Enter a Subject. Then enter the text for your conversation under the 'Markdown' tab. You don't have to use markdown markers. You can just type in regular text without any formatting. If you use markdown, then you can click on the helper buttons to insert the corresponding markdown symbols. The Preview tab will render your markdown symbols and allow you to view the message as it would appear for all readers of the message."
          imageTwoPath="recipients"
          titleTwo="Step 2: Select Conversation Recipients"
          infoTwo="Make a selection from the recipients drop down. After you make your selection click 'Add'. This will either open a modal dialog for selecting subsequent selections, or it will add the conversation group as your recipient. You must enter at least one recipient."
          imageThreePath="attachments"
          titleThree="Step 3: Upload Attachments"
          infoThree="Upload a mixture of one or more image and/or pdf attachments, by clicking on the 'Drag & Drop' section. The pending uploads will display a thumbnail preview after selecting each upload file. Click the 'Create Message' button to create the message."
        />
      </label>
    </div>
    <div class="mt-6">
      <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(processSubmit)">
            <fieldset>
              <div class="form-grid">
                <div class="column is-12 pl-3">
                  <label class="label">Recipients</label>
                  <div>
                    <div
                      v-if="
                        typeof recipientsSelected !== 'undefined' &&
                          recipientsSelected !== undefined &&
                          recipientsSelected
                      "
                      :style="
                        `
                        display: inline;
                      `
                      "
                    >
                      <div
                        v-if="
                          recipientsSelected !== undefined &&
                            recipientsSelected &&
                            recipientsSelected.length === 0
                        "
                      >
                        <span style="font-style:italic">No recipients added</span>
                      </div>
                      <div
                        class="pt-3"
                        v-for="(recipient, index) in recipientsSelected"
                        :key="index"
                        :style="{
                          verticalAlign: 'top',
                          marginLeft: '0px'
                        }"
                      >
                        <b-taglist attached>
                          <b-tag
                            type="is-light"
                            style="background-color: white !important;"
                            closable
                            @close="eraseRecipient({ recipient })"
                            >{{ recipient.recipient }}</b-tag
                          >
                        </b-taglist>
                      </div>
                    </div>
                    <div class="pt-5 pb-5">
                      <div :style="{ padding: '0 12px' }"></div>
                      <b-button type="is-primary is-rounded" @click.prevent="openRecipentModal"
                        >Add Recipient</b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="column is-12">
                  <div
                    class="column is-12"
                    v-if="
                      vendorHoaList &&
                        vendorHoaList != undefined &&
                        vendorHoaList.length > 0 &&
                        fromAskaQuestion === false
                    "
                  >
                    <label class="label">Vendor Hoas</label>
                    <valid-select
                      placeholder="Vendor Hoas"
                      vid="vendorHoaID"
                      v-model="vendorHoaID"
                      rules="required"
                      aria-required="Please select an Hoa"
                    >
                      <option
                        v-for="option in vendorHoaList"
                        :value="option.value"
                        :key="option.value"
                        >{{ option.label }}</option
                      >
                    </valid-select>
                  </div>
                  <div class="column is-12">
                    <valid-input
                      style="width:50px !important;"
                      name="Subject"
                      label="Subject"
                      maxlength="8000"
                      type="text"
                      vid="Subject"
                      placeholder="Subject"
                      spellcheck="true"
                      aria-label="Subject"
                      rules="max:8000|required"
                      class="is-small"
                      v-model="subject"
                    />
                  </div>
                  <div class="column is-12">
                    <Markdown :message.sync="message" />
                  </div>
                  <div class="column is-12">
                    <section class="pt-6 violation-image-upload" style="float: center !important;">
                      <b-field class="file">
                        <b-upload
                          v-model="files"
                          multiple
                          drag-drop
                          accept="application/pdf, image/png, image/jpeg, image/jpg"
                          class="rectangle"
                        >
                          <section class="force-center float-center">
                            <div class="force-center float-center">
                              <div class="force-center float-center" style="padding-left: 166px">
                                <img
                                  class="Fill-1-Copy-11 force-center float-center"
                                  src="@/assets/images/fill-1-copy-11.svg"
                                  alt="Upload"
                                />
                              </div>
                              <div class="force-center float-center">
                                <div class="Drag-Drop-your-fil">
                                  <div class="text-style-1">Drag & Drop</div>
                                  <div>Drop your files here or click to upload.</div>
                                  <div>Drop a PNG, JPG, JPEG, or PDF file here, or click to</div>
                                  <div>Select one or more files from your computer.</div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>

                      <ul id="gallery" class="flex flex-1 flex-wrap -m-1">
                        <li
                          v-show="!previews.length"
                          class="h-full w-full text-center flex flex-col items-center justify-center items-center"
                        >
                          <img
                            class="mx-auto w-32 is-center"
                            src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                            alt="no data"
                          />
                          <span class="text-small text-gray-500">No files selected</span>
                        </li>
                        <li
                          v-for="(image, index) in previews"
                          :key="index"
                          class="p-1 flex w-1/4 h-32"
                        >
                          <div
                            class="align-items-center relative w-full h-full bg-cover"
                            :style="`background-image: url(${image});`"
                          >
                            <button
                              class="delete is-small"
                              type="button"
                              @click="deleteDropFile(index)"
                            />
                            <label
                              class="absolute inset-x-0 bottom-0 bg-black bg-opacity-50 z-10 p-2"
                            >
                              <span style="color:white;">{{ files[index].name }}</span>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </section>
                  </div>
                  <div class="pt-5">
                    <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                      Create Message
                    </button>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>

    <Modal :toggle.sync="toggleCommittee">
      <CommitteeModal :toggle="toggleCommittee" :VendorHoaID="vendorHoaID"> </CommitteeModal>
    </Modal>
    <Modal :toggle.sync="toggleVendor">
      <VendorModal :toggle="toggleVendor" :VendorHoaID="vendorHoaID"> </VendorModal>
    </Modal>
    <Modal :toggle.sync="toggleHomeowner">
      <HomeownerModal :toggle="toggleHomeowner" :VendorHoaID="vendorHoaID"> </HomeownerModal>
    </Modal>
    <Modal :toggle.sync="toggleBoard">
      <BoardModal :toggle="toggleBoard" :VendorHoaID="vendorHoaID"> </BoardModal>
    </Modal>
    <Modal :toggle.sync="toggleManagement">
      <ManagementModal :toggle="toggleManagement" :VendorHoaID="vendorHoaID"> </ManagementModal>
    </Modal>
    <Modal :toggle.sync="toggleRecipients">
      <RecipientsModal
        :toggle="toggleRecipients"
        :recipientList="recipientList"
        :addLabel="addLabel"
        :addValue="parseInt(addValue)"
        :filterByString="filterBy"
        :onChange="onRecipientChange"
        :addRecipientModal="addRecipientModal"
      >
      </RecipientsModal>
    </Modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
import Markdown from '@/components/markdown'
import Modal from '@/components/Modal'
import CommitteeModal from './components/committees'
import VendorModal from './components/vendors'
import HomeownerModal from './components/homeowners'
import BoardModal from './components/boardOfDirectors'
import ManagementModal from './components/accountManagers'
import RecipientsModal from './components/recipients'
import Info from '@/components/icons/Info'

export default {
  props: {},

  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider,
    Modal,
    CommitteeModal,
    VendorModal,
    HomeownerModal,
    BoardModal,
    ManagementModal,
    RecipientsModal,
    Markdown,
    Info
  },

  mounted() {
    this.imageOnePath = '@/assets/images/radiobuttons.png'
    this.reload()
  },

  watch: {
    message() {
      console.debug('message=' + this.message)
    },
    files() {
      this.updateAttachments()
    },
    toggleCommittee() {
      if (this.toggleCommittee == false) {
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggleCommittee = false))
        }

        if (this.isDebug === true)
          console.debug('watched toggle other committee selected=' + this.selectedOtherCommitteeID)

        if (
          this.selectedOtherCommitteeID &&
          this.selectedOtherCommitteeID != undefined &&
          this.selectedOtherCommitteeID > 0
        ) {
          this.addRecipient('Other Committee')
        }
      }
    },
    toggleRecipients() {
      if (this.toggleRecipients == false) {
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggleRecipients = false))
        }
      }
    },
    toggleBoard() {
      if (this.toggleBoard == false) {
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggleBoard = false))
        }

        if (this.isDebug === true)
          console.debug('watched toggle board selected=' + this.selectedBoardCommitteeID)

        if (
          this.selectedBoardCommitteeID &&
          this.selectedBoardCommitteeID != undefined &&
          this.selectedBoardCommitteeID > 0
        ) {
          this.addRecipient('Board of Directors')
        }
      }
    },
    toggleVendor() {
      if (this.toggleVendor == false) {
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggleVendor = false))
        }

        if (this.isDebug === true)
          console.debug('watched toggle vendors selected=' + this.selectedVendorIDs)

        if (
          this.selectedVendorIDs &&
          this.selectedVendorIDs != undefined &&
          this.selectedVendorIDs.length > 0
        ) {
          this.addRecipient('Vendors')
        }
      }
    },
    toggleHomeowner() {
      if (this.toggleHomeowner == false) {
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggleHomeowner = false))
        }

        if (this.isDebug === true)
          console.debug(
            'watched toggle homeowners selected=' + JSON.stringify(this.selectedOwnerIDs)
          )

        if (
          this.selectedOwnerIDs &&
          this.selectedOwnerIDs != undefined &&
          this.selectedOwnerIDs.length > 0
        ) {
          this.addRecipient('Homeowners')
        }
      }
    },
    toggleManagement() {
      if (this.toggleManagement == false) {
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggleManagement = false))
        }

        if (this.isDebug === true)
          console.debug(
            'watched toggle account managers selected=' +
              JSON.stringify(this.selectedAccountManagerIDS)
          )

        if (
          this.selectedAccountManagerIDS &&
          this.selectedAccountManagerIDS != undefined &&
          this.selectedAccountManagerIDS.length > 0
        ) {
          this.addRecipient('Management Portal Users')
        }
      }
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser']),
      ...mapGetters('conversation', [
        'selectedVendorIDs',
        'selectedVendorIDsJson',
        'selectedBoardCommitteeID',
        'selectedOtherCommitteeID',
        'selectedOwnerIDs',
        'selectedOwnerIDsJson',
        'selectedAccountManagerIDS',
        'selectedAccountManagerIDsJson'
      ])
    })
  },

  data,
  methods,

  i18n: {
    messages: {
      en: { conversation: { title: 'New Conversation' } }
    }
  }
}
</script>
<style lang="scss">
.uploader {
  .rectangle {
    width: 244px;
    height: 107px;
    margin: 13px 16px 11px 53px;
    padding: 18px 25px 8px 3px;
    border-radius: 4px;
    border: #979797;
  }
  .Drag-Drop-your-fil {
    width: 192px;
    height: 34px;
    margin: 7px 0 8px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #838383;
  }

  .Drag-Drop-your-fil .text-style-1 {
    font-weight: bold;
    color: #6e7776;
  }

  img.Fill-1-Copy-11 {
    width: 24px;
    height: 30px;
    margin: 0 85px 7px 83px;
    object-fit: contain;
  }
}
</style>
