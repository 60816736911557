import { conversationList } from '@/services/Conversations/store'
import { notifyError } from '@/services/notify'
import $ from 'jquery'

export const methods = {
  async reload() {
    this.loading = true

    this.$store.dispatch('conversation/saveOtherCommitteeID', {
      committeeID: 0
    })

    this.getCommitteesDropDown()

    this.loading = false
  },

  async getCommitteesDropDown() {
    if (this.isDebug == true) console.debug('in getCommitteesDropDown()....')
    await conversationList
      .dispatch('loadCommittees', {
        hoaID: this.hoaId && this.hoaId > 0 ? this.hoaId : this.VendorHoaID,
        isBoardMember: false
      })
      .then(({ list }) => {
        if (list) {
          this.committeeList = list
        }
      })
  },

  open() {
    this.subject = ''
    this.isOpen = true
    this.loading = false
  },

  async processSubmit() {
    if (!this.committeeID || this.committeeID == undefined || this.committeeID <= 0) {
      notifyError('A committee selection is required.')
      return
    }

    this.$store.dispatch('conversation/saveOtherCommitteeID', {
      committeeID: this.committeeID
    })

    this.closeModal()
  },

  closeModal() {
    // auto-close modal
    if (this.isDebug === true) console.debug('committeeID=' + this.committeeID)

    this.$emit('update:toggle', (this.toggleCommittee = false))
    $('#clickclose').click()
  }
}
