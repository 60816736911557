/*
  import { List as ListHoaAccountManagingUsers } from '@/services/AccountSettings/HoaAccountManagingUser/List'

  const { list } = await ListHoaAccountManagingUsers ({
    hoaID
  });
*/

import kms from '@/services/kms'
import _get from 'lodash/get'

const isDebug = true

export async function List({ hoaID }) {
  try {
    let result = await kms.get('/AccountSettings/HoaAccountManagingUser/List', {
      params: {
        hoaID
      }
    })

    if (isDebug === true)
      console.debug('HoaAccountManagingUser List=' + JSON.stringify(result.results))

    return {
      list: _get(result, 'results', [])
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    list: []
  }
}
