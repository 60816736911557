<template>
  <div message-modal>
    <div class="pb-1 is-size-4" v-if="orderedHomeowners">
      Home Owner
    </div>
    <div class="columns box is-12">
      <div class="box list-content column is-12">
        <b-field label="Search">
          <b-input @input="filterList" v-model="filterBy" />
        </b-field>
        <b-table
          :data="orderedHomeowners"
          :loading="loading"
          :striped="true"
          :sticky-header="true"
          :checked-rows.sync="checkedRows"
          checkable
          :checkbox-position="checkboxPosition"
          :style="{ maxHeight: '400px' }"
        >
          <template slot="empty">
            <empty-list v-if="!loading" />
          </template>
          <b-table-column label="User Email" field="email" sortable v-slot="props"
            ><span v-if="props.row.email && props.row.email != undefined">{{
              props.row.email || ''
            }}</span>
            <span v-else>&nbsp;</span>
          </b-table-column>
          <b-table-column label="User Name" field="name" sortable v-slot="props"
            ><span v-if="props.row.primaryOwner && props.row.primaryOwner != undefined">{{
              props.row.primaryOwner.name || ''
            }}</span>
            <span v-else>&nbsp;</span>
          </b-table-column>
          <b-table-column label="Property Address" field="address" sortable v-slot="props"
            ><span v-if="props.row.address && props.row.address != undefined">{{
              props.row.address || ''
            }}</span>
            <span v-else>&nbsp;</span>
          </b-table-column>
        </b-table>
      </div>
    </div>
    <div class="pt-5">
      <button
        type="button"
        :disabled="loading"
        class="button is-primary is-rounded"
        @click.prevent="processSubmit"
      >
        Select
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import EmptyList from '@/components/EmptyList'
import _ from 'lodash'

export default {
  props: {
    VendorHoaID: Number
  },

  components: {
    EmptyList
  },

  mounted() {
    this.reload()
  },

  computed: {
    orderedHomeowners: function() {
      return _.orderBy(this.homeownerList, 'primaryOwner.name', 'asc')
    },
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
