var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"message-modal":""}},[(_vm.recipientList !== undefined && _vm.recipientList)?_c('div',{staticClass:"is-size-4"},[_vm._v(" Add Participants ")]):_vm._e(),(!_vm.friendlyMessage)?_c('div',[_c('ValidationObserver',{ref:"formGroup",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmAddNewRecipients)}}},[_c('fieldset',[_c('div',{staticClass:"form-grid"},[_c('div',{staticClass:"column is-12"},[_c('label',{staticClass:"label"},[_vm._v("Recipients")]),_c('div',[(
                      typeof _vm.recipientsSelected !== 'undefined' &&
                        _vm.recipientsSelected !== undefined &&
                        _vm.recipientsSelected
                    )?_c('div',{style:("\n                      display: inline;\n                    ")},[(
                        _vm.recipientsSelected !== undefined &&
                          _vm.recipientsSelected &&
                          _vm.recipientsSelected.length === 0
                      )?_c('div',[_c('span',{staticStyle:{"font-style":"italic"}},[_vm._v("No recipients added")])]):_vm._e(),_vm._l((_vm.recipientsSelected),function(recipient,index){return _c('div',{key:index,staticClass:"pt-3",style:({
                        verticalAlign: 'top',
                        marginLeft: '0px'
                      })},[_c('b-taglist',{attrs:{"attached":""}},[_c('b-tag',{staticStyle:{"background-color":"white !important"},attrs:{"type":"is-light","closable":""},on:{"close":function($event){return _vm.eraseRecipient({ recipient: recipient })}}},[_vm._v(_vm._s(recipient.recipient))])],1)],1)})],2):_vm._e()])]),_c('div',{staticClass:"column is-12"},[(_vm.vendorHoaList && _vm.vendorHoaList != undefined && _vm.vendorHoaList.length > 0)?_c('div',{staticClass:"column is-12"},[_c('label',{staticClass:"label"},[_vm._v("Vendor Hoas")]),_c('valid-select',{attrs:{"placeholder":"Vendor Hoas","vid":"vendorHoaID","rules":"required","aria-required":"Please select an Hoa"},model:{value:(_vm.vendorHoaID),callback:function ($$v) {_vm.vendorHoaID=$$v},expression:"vendorHoaID"}},_vm._l((_vm.vendorHoaList),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(_vm._s(option.label))])}),0)],1):_vm._e()])])]),_c('fieldset',[_c('ValidationProvider',{attrs:{"vid":"providerErrors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)])])]}}],null,false,472300010)})],1):_vm._e(),_c('div',[_c('ValidationObserver',{ref:"formGroup",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var handleSubmit = ref.handleSubmit;
return [_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.processSubmit)}}},[_c('fieldset',[_c('div',{staticClass:"form-grid"},[_c('div',{staticClass:"column is-12 pl-3"},[_c('label',{staticClass:"label"},[_vm._v("Recipients")]),_c('div',[_c('div',{style:({ display: 'flex', alignItems: 'center' })},[_c('Recipient',{ref:"recipient",attrs:{"addLabel":_vm.addLabel,"addValue":parseInt(_vm.addValue),"filterBy":_vm.filterByString,"onChange":_vm.onRecipientChange}}),_c('div',{style:({ padding: '0 12px' })}),_c('b-button',{style:({ marginLeft: '12px' }),attrs:{"type":"is-primary"},on:{"click":function($event){$event.preventDefault();_vm.addRecipientModal(_vm.$refs.recipient.getValue())}}},[_vm._v("Add")])],1),_c('div',{staticClass:"pt-5"},[_c('div',{style:({ padding: '0 12px' })}),_c('button',{staticClass:"button is-primary is-rounded",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.processRecipientsSubmit.apply(null, arguments)}}},[_vm._v(" Submit ")])])])])])]),_c('fieldset',[_c('ValidationProvider',{attrs:{"vid":"providerErrors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)])])]}}])})],1),_c('Modal',{attrs:{"toggle":_vm.toggleBoard},on:{"update:toggle":function($event){_vm.toggleBoard=$event}}},[_c('BoardModal',{attrs:{"toggle":_vm.toggleBoard,"VendorHoaID":_vm.vendorHoaID}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }