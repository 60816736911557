<template>
  <span class="pl-2">
    <button
      class="pt-2"
      style="vertical-align:sub;"
      @click.prevent="isActive = !isActive"
      tabindex="-1"
    >
      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
        />
      </svg>
    </button>
    <b-message type="is-info" v-model="isActive" aria-close-label="Close message">
      <div class="subtitle">{{ information || '' }}</div>
      <article class="media" v-if="imageOnePath">
        <figure class="media-left">
          <p class="image is-164x164" v-if="imageOnePath === 'imageOnePath'">
            <img style="width: 164px;" :src="require('@/assets/images/checkboxes.png')" />
          </p>
          <p class="image is-164x164" v-else-if="imageOnePath === 'markdown'">
            <img style="width: 164px;" :src="require('@/assets/images/markdown.png')" />
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <p>
              <strong class="subtitle">{{ titleOne }}</strong>
              <br />
              <span>{{ infoOne }}</span>
            </p>
          </div>
          <nav class="level is-mobile">
            <div class="level-left">
              <a class="level-item">
                <span class="icon is-small"><i class="fas fa-reply"></i></span>
              </a>
              <a class="level-item">
                <span class="icon is-small"><i class="fas fa-retweet"></i></span>
              </a>
              <a class="level-item">
                <span class="icon is-small"><i class="fas fa-heart"></i></span>
              </a>
            </div>
          </nav>
        </div>
      </article>
      <article class="media" v-if="imageTwoPath">
        <figure class="media-left">
          <p class="image is-164x164" v-if="imageTwoPath === 'imageTwoPath'">
            <img style="width: 164px;" :src="require('@/assets/images/radiobuttons.png')" />
          </p>
          <p class="image is-164x164" v-else-if="imageTwoPath === 'recipients'">
            <img style="width: 164px;" :src="require('@/assets/images/recipients.png')" />
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <p>
              <strong class="subtitle">{{ titleTwo }}</strong>
              <br />
              {{ infoTwo }}
            </p>
          </div>
          <nav class="level is-mobile">
            <div class="level-left">
              <a class="level-item">
                <span class="icon is-small"><i class="fas fa-reply"></i></span>
              </a>
              <a class="level-item">
                <span class="icon is-small"><i class="fas fa-retweet"></i></span>
              </a>
              <a class="level-item">
                <span class="icon is-small"><i class="fas fa-heart"></i></span>
              </a>
            </div>
          </nav>
        </div>
      </article>
      <article class="media" v-if="imageThreePath">
        <figure class="media-left">
          <p class="image is-164x164" v-if="imageThreePath === 'imageThreePath'">
            <img style="width: 164px;" :src="require('@/assets/images/text.png')" />
          </p>
          <p class="image is-164x164" v-else-if="imageThreePath === 'attachments'">
            <img style="width: 164px;" :src="require('@/assets/images/attachments.png')" />
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <p>
              <strong class="subtitle">{{ titleThree }}</strong>
              <br />
              {{ infoThree }}
            </p>
          </div>
          <nav class="level is-mobile">
            <div class="level-left">
              <a class="level-item">
                <span class="icon is-small"><i class="fas fa-reply"></i></span>
              </a>
              <a class="level-item">
                <span class="icon is-small"><i class="fas fa-retweet"></i></span>
              </a>
              <a class="level-item">
                <span class="icon is-small"><i class="fas fa-heart"></i></span>
              </a>
            </div>
          </nav>
        </div>
      </article>
      <article class="media" v-if="imageFourPath">
        <figure class="media-left">
          <p class="image is-164x164">
            <img style="width: 164px;" :src="require('@/assets/images/scale.png')" />
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <p>
              <strong class="subtitle">{{ titleFour }}</strong>
              <br />
              {{ infoFour }}
            </p>
          </div>
          <nav class="level is-mobile">
            <div class="level-left">
              <a class="level-item">
                <span class="icon is-small"><i class="fas fa-reply"></i></span>
              </a>
              <a class="level-item">
                <span class="icon is-small"><i class="fas fa-retweet"></i></span>
              </a>
              <a class="level-item">
                <span class="icon is-small"><i class="fas fa-heart"></i></span>
              </a>
            </div>
          </nav>
        </div>
      </article> </b-message
  ></span>
</template>

<script>
export default {
  props: {
    // message title
    title: {
      type: String,
      default: ''
    },

    // info
    information: {
      type: String,
      default: 'None'
    },

    // 1st image path
    imageOnePath: {
      type: String,
      default: ''
    },
    titleOne: {
      type: String,
      default: ''
    },
    infoOne: {
      type: String,
      default: ''
    },

    // 2nd image path
    imageTwoPath: {
      type: String,
      default: ''
    },
    titleTwo: {
      type: String,
      default: ''
    },
    infoTwo: {
      type: String,
      default: ''
    },

    // 3rd image path
    imageThreePath: {
      type: String,
      default: ''
    },
    titleThree: {
      type: String,
      default: ''
    },
    infoThree: {
      type: String,
      default: ''
    },

    // 4th image path
    imageFourPath: {
      type: String,
      default: ''
    },
    titleFour: {
      type: String,
      default: ''
    },
    infoFour: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: false
    }
  }
}
</script>
