<template>
  <div class="column is-12">
    <b-tabs v-model="activeTab" position="is-left">
      <b-tab-item label="Markdown" :style="{ marginLeft: '-22px' }">
        <div>
          <b-input
            id="mrkdown"
            ref="ta"
            style="width:auto !important;"
            name="Message"
            maxlength="8000"
            type="textarea"
            vid="Message"
            placeholder="Markdown"
            spellcheck="true"
            aria-label="Markdown"
            rules="max:8000|required"
            class="is-small"
            v-model="message"
          />
        </div>
        <div>
          <b-button> <i role="button" @click.prevent="addH1()" class="icon-markuptitle1"/></b-button
          ><b-button><i role="button" @click.prevent="addH2()" class="icon-markuptitle2"/></b-button
          ><b-button>
            <i role="button" @click.prevent="addH3()" class="icon-markuptitle3"/></b-button
          ><b-button>
            <i role="button" @click.prevent="addBold()" class="icon-markupbold"/></b-button
          ><b-button>
            <i role="button" @click.prevent="addItalics()" class="icon-markupitalic"/></b-button
          ><b-button>
            <i
              role="button"
              @click.prevent="addBulletedList()"
              class="icon-markupbulletlist"/></b-button
          ><b-button>
            <i
              role="button"
              @click.prevent="addNumberedList()"
              class="icon-markupnumberlist"/></b-button
          ><b-button>
            <i role="button" @click.prevent="addReturn()" class="icon-carriagereturn"
          /></b-button>
        </div>
      </b-tab-item>
      <b-tab-item label="Preview">
        <vue-markdown :source="message"></vue-markdown>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: 'Markup',
  components: {
    VueMarkdown
  },
  props: {
    message: String
  },
  data() {
    return {
      isDebug: true,
      activeTab: 0
    }
  },
  created() {},
  computed: {},
  watch: {
    message() {
      this.$emit('update:message', this.message)
    }
  },
  mounted() {},
  methods: {
    addH1() {
      console.debug('H1 was clicked...')
      this.cursorAdd('# ', false)
    },
    addH2() {
      console.debug('H2 was clicked...')
      this.cursorAdd('## ', false)
    },
    addH3() {
      console.debug('H3 was clicked...')
      this.cursorAdd('### ', false)
    },
    addBold() {
      console.debug('Bold was clicked...')

      this.cursorAdd('**', true)
    },
    addItalics() {
      console.debug('Italics was clicked...')
      this.cursorAdd('_', true)
    },
    addReturn() {
      console.debug('Return was clicked...')
      this.cursorAddTwo('<br /><br />', false)
    },
    addBulletedList() {
      console.debug('Bulleted List was clicked...')
      this.cursorAdd('<li>', false)
    },
    addNumberedList() {
      console.debug('Numbered List was clicked...')
      this.cursorAddAndEndTag('<ol>', '')
    },
    addNumberedListDeprecated() {
      console.debug('Numbered List was clicked...')
      let counter = 0

      const splitMessage = this.message.split('\n')

      let newMessage = ''

      Array.from(splitMessage).forEach(e => {
        counter = counter + 1
        const checker = `${counter}`
        if (this.message.indexOf(checker) === -1 && e.length > 0) {
          newMessage = newMessage + `${checker}.${splitMessage[counter - 1]}\n`
        }
      })

      this.message = newMessage
    },
    cursorAdd(insert, ending) {
      let tArea = this.$refs.ta

      if (0 == insert) {
        return
      }

      // get cursor's position:
      const tempArea = tArea.value
      const startPos = document.getElementById('mrkdown').selectionStart
      const tempFromStart = tempArea.substring(startPos, tempArea.length)
      console.debug('tempFromStart=' + tempFromStart)
      const firstWord = tempFromStart.split('\n')[0]
      console.debug('firstWord=' + firstWord)
      const tmpEnd = ending ? insert : ''
      const replaceWord = insert + firstWord + tmpEnd

      console.debug('positions=' + document.getElementById('mrkdown').selectionEnd)

      // insert:
      const tempMessage = tempArea.replace(firstWord, replaceWord)
      this.message = tempMessage
    },
    cursorAddAndEndTag(insert, endtag) {
      let tArea = this.$refs.ta

      if (0 == insert) {
        return
      }

      // get cursor's position:
      const tempArea = tArea.value
      const startPos = document.getElementById('mrkdown').selectionStart
      const tempFromStart = tempArea.substring(startPos, tempArea.length)
      console.debug('tempFromStart=' + tempFromStart)
      const firstWord = tempFromStart.split('\n')[0]
      console.debug('firstWord=' + firstWord)

      let count = (tempArea.match(/<ol>/g) || []).length
      const countUp = (count = count + 1)

      const replaceWord = insert + countUp + '. ' + firstWord + endtag

      console.debug('positions=' + document.getElementById('mrkdown').selectionEnd)

      // insert:
      const tempMessage = tempArea.replace(firstWord, replaceWord)
      this.message = tempMessage
    },
    cursorAddTwo(insert, ending) {
      let tArea = this.$refs.ta

      if (0 == insert) {
        return
      }

      const startPos = document.getElementById('mrkdown').selectionStart
      const tmpStr = tArea.value
      const tmpEnd = ending ? insert : ''
      const endValue = tmpStr.substring(startPos, tmpStr.length) + tmpEnd
      console.debug('positions=' + document.getElementById('mrkdown').selectionEnd)
      this.message = tmpStr.substring(0, startPos) + insert + endValue
    }
  }
}
</script>
<style lang="scss" scoped>
#ellipsiscontrol {
  .ellipsis {
    cursor: pointer;
    width: 60%;
    text-align: left;
    border: 1px solid #022954;
  }

  .ellipsisbutton {
    width: 120px;
  }
}
</style>
