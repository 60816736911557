export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    openOnFocus: true,
    vendorList: [],
    vendorListCopy: [],
    vendorIds: [],
    vendorIdsJson: [],
    toggleVendor: false,
    checkboxPosition: 'left',
    checkedRows: [],
    filterBy: '',
    toggleBoard: false,
    friendlyMessage: '',
    vendorHoaList: []
  }
}
