import { conversationList } from '@/services/Conversations/store'
import { notifyError, notifyMessage } from '@/services/notify'
import _get from 'lodash/get'
import $ from 'jquery'

export const methods = {
  async reload() {
    this.loading = true

    if (typeof this.authUser.vendorGlobalID === 'number') {
      this.getVendorHoaDropDown()
    }

    this.loading = false
  },

  async eraseRecipient({ recipient }) {
    const recipientsSelected = this.recipientsSelected

    if (recipientsSelected !== undefined && recipientsSelected) {
      for (let a = 0; a < recipientsSelected.length; a++) {
        const { recipient: _recipient } = recipientsSelected[a]

        let tempIds = []

        if (_recipient === recipient.recipient) {
          this.recipientsSelected.splice(a, 1)

          if (_recipient === 'Other Committee') {
            await this.$store.dispatch('conversation/saveOtherCommitteeID', {
              committeeID: 0
            })
          } else if (_recipient.indexOf('Vendor') !== -1) {
            Array.from(this.recipientsSelected).forEach(f => {
              if (f && f.id) {
                tempIds.push(f.id)
              }
            })

            await this.$store.dispatch('conversation/saveVendorIDs', {
              vendorIds: tempIds
            })
          } else if (_recipient.indexOf('VendorRecipient') !== -1) {
            Array.from(this.recipientsSelected).forEach(f => {
              if (f && f.id) {
                tempIds.push(f.id)
              }
            })

            await this.$store.dispatch('conversation/saveVendorRequestIDs', {
              vendorRequestIds: tempIds
            })
          } else if (_recipient === 'Board of Directors') {
            await this.$store.dispatch('conversation/saveBoardCommitteeID', {
              committeeID: 0
            })
          } else if (_recipient.indexOf('Management Portal User') !== -1) {
            if (this.isDebug === true)
              console.debug('Management=' + JSON.stringify(this.recipientsSelected))

            Array.from(this.recipientsSelected).forEach(f => {
              if (f && f.id) {
                tempIds.push(f.id)
              }
            })

            await this.$store.dispatch('conversation/saveAccountManagerIDs', {
              accountManagerIds: tempIds
            })
          }

          break
        }
      }
    }
  },

  async getVendorHoaDropDown() {
    if (this.isDebug == true) console.debug('in getVendorHoaDropDown()...')
    await conversationList
      .dispatch('getVendorHoaList', {
        vendorGlobalID: this.authUser.vendorGlobalID
      })
      .then(({ list }) => {
        if (list) {
          this.vendorHoaList = list
        }
      })
  },

  async processRecipientsSubmit() {
    if (
      !this.recipientsSelected ||
      this.recipientsSelected == undefined ||
      this.recipientsSelected.length === 0
    ) {
      notifyError('At least one recipient is required.')
      return
    }

    if (
      (!this.vendorHoaID || this.vendorHoaID <= 0) &&
      this.vendorHoaList &&
      this.vendorHoaList != undefined &&
      this.vendorHoaList.length > 0
    ) {
      notifyError('Please select an hoa before adding a recipient.')
      return
    }

    const recipientsSelected = this.recipientsSelected

    let payload = {
      privateThreadID:
        this.privateThreadID && this.privateThreadID > 0 ? this.privateThreadID : null
    }

    for (let a = 0; a < recipientsSelected.length; a++) {
      const { recipient: recipient } = recipientsSelected[a]

      if (recipient === 'Other Committee') {
        payload.otherCommitteeID = this.selectedOtherCommitteeID
      } else if (recipient.indexOf('Vendor') !== -1) {
        payload.vendorIds = this.selectedVendorIDs
      } else if (recipient.indexOf('Homeowner') !== -1) {
        payload.homeOwnerIds = this.selectedOwnerIDs
      } else if (recipient === 'Board of Directors') {
        payload.boardCommitteeID = this.selectedBoardCommitteeID
      } else if (recipient.indexOf('Management Portal User') !== -1) {
        payload.accountManagingUserIds = this.selectedAccountManagerIDS
      } else if (recipient === 'Accountant') {
        payload.isAccountantRecipient = true
      } else if (recipient === 'HoaMyWay Administrative Team') {
        payload.areAdminRecipients = true
      }
    }

    if (this.isDebug === true) console.debug('payload=' + JSON.stringify(payload))

    await conversationList.dispatch('addParticipantsToPrivateThreadMessage', {
      privateThreadID: payload.privateThreadID ? payload.privateThreadID : null,
      vendorGlobalID: _get(this.authUser, 'vendorGlobalID', null),
      boardCommitteeID: payload.boardCommitteeID ? payload.boardCommitteeID : null,
      otherCommitteeID: payload.otherCommitteeID ? payload.otherCommitteeID : null,
      vendorIds: payload.vendorIds && payload.vendorIds.length > 0 ? payload.vendorIds : null,
      homeOwnerIds:
        payload.homeOwnerIds && payload.homeOwnerIds.length > 0 ? payload.homeOwnerIds : null,
      accountManagingUserIds:
        payload.accountManagingUserIds && payload.accountManagingUserIds.length > 0
          ? payload.accountManagingUserIds
          : null,
      isAccountantRecipient: payload.isAccountantRecipient ? payload.isAccountantRecipient : null,
      areAdminRecipients: payload.areAdminRecipients ? payload.areAdminRecipients : null,
      done: async ({ details }) => {
        if (this.isDebug === true) console.debug('done details=' + JSON.stringify(details))

        if (details && details === true) {
          this.reload()
          notifyMessage('The selected recipients have been added to this conversation.')
        } else {
          notifyError('There was a problem adding participants to this message.')
        }
      }
    })

    this.closeModal()
  },

  async getVendorsDropDown() {
    if (this.isDebug == true) console.debug('in getVendorsDropDown()...')
    await conversationList
      .dispatch('loadVendors', {
        hoaID: this.hoaId && this.hoaId > 0 ? this.hoaId : this.VendorHoaID
      })
      .then(({ list }) => {
        if (list) {
          this.vendorList = list
          this.vendorListCopy = list
        }
      })
  },

  filterList() {
    if (this.isDebug === true) console.debug('in filterList...')
    if (this.filterBy && this.vendorList) {
      if (this.filterBy.length === 0) {
        this.vendorList = this.vendorListCopy
      } else {
        this.vendorList = this.vendorList.filter(f => f.name.indexOf(this.filterBy) !== -1)
      }
    } else {
      this.vendorList = this.vendorListCopy
    }
  },

  open() {
    this.subject = ''
    this.isOpen = true
    this.loading = false
  },

  async processSubmit() {
    if (!this.checkedRows || this.checkedRows == undefined || this.checkedRows.length === 0) {
      notifyError('At least one vendor is required.')
      return
    }

    Array.from(this.checkedRows).forEach(element => {
      if (element) {
        if (this.isDebug === true) console.debug('element' + JSON.stringify(element))

        if (element.name && element.name != undefined && element.vendorID) {
          this.vendorIds.push(element.vendorID)
          this.vendorIdsJson.push({ name: element.name, vendorID: element.vendorID })
        } else if (
          element.doingBusinessAs &&
          element.doingBusinessAs != undefined &&
          element.vendorID
        ) {
          this.vendorIds.push(element.vendorID)
          this.vendorIdsJson.push({ name: element.doingBusinessAs, vendorID: element.vendorID })
        }
      }
    })

    if (this.isDebug === true) console.debug('vendorIds=' + JSON.stringify(this.vendorIds))

    this.$store.dispatch('conversation/saveVendorIDs', {
      vendorIds: this.vendorIds,
      vendorIdsJson: this.vendorIdsJson
    })

    this.closeModal()
  },

  closeModal() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggleVendor = false))
    $('#clickclose').click()
  }
}
