import { List as ListHoaAccountManagingUsers } from '@/services/AccountSettings/HoaAccountManagingUser/List'
import { notifyError } from '@/services/notify'
import $ from 'jquery'

export const methods = {
  async reload() {
    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    this.$store.dispatch('conversation/saveAccountManagerIDs', {
      accountManagerIds: []
    })

    this.getAccountManagers()

    loadingComponent.close()
    this.loading = false
  },

  async getAccountManagers() {
    if (this.isDebug == true) console.debug('in getAccountManagers()...')

    await ListHoaAccountManagingUsers({
      hoaID: this.hoaId && this.hoaId > 0 ? this.hoaId : this.VendorHoaID
    }).then(({ list }) => {
      if (list) {
        if (this.isDebug === true) console.debug('accountManagingUsers=' + JSON.stringify(list))
        this.accountManagingUserList = list.filter(
          f =>
            f.accountManagingUser &&
            f.accountManagingUser.identityInformation &&
            f.accountManagingUser.identityInformation.name &&
            f.accountManagingUser.identityInformation.name !== ''
        )

        this.accountManagingUserListCopy = this.accountManagingUserList
      }
    })
  },

  filterList() {
    if (this.isDebug === true) console.debug('in filterList...')
    if (this.filterBy && this.accountManagingUserList) {
      if (this.filterBy.length === 0) {
        this.accountManagingUserList = this.accountManagingUserListCopy
      } else {
        this.accountManagingUserList = this.accountManagingUserList.filter(
          f =>
            f.accountManagingUser.identityInformation.name.indexOf(this.filterBy) !== -1 ||
            f.accountManagingUser.identityInformation.email.indexOf(this.filterBy) !== -1
        )
      }
    } else {
      this.accountManagingUserList = this.accountManagingUserListCopy
    }
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  async processSubmit() {
    if (!this.checkedRows || this.checkedRows == undefined || this.checkedRows.length === 0) {
      notifyError('At least one account manager is required.')
      return
    }

    Array.from(this.checkedRows).forEach(element => {
      if (element) {
        if (this.isDebug === true) console.debug('element' + JSON.stringify(element))

        if (
          typeof accountManagingUserID !== 'number' &&
          element.accountManagingUserID &&
          element.accountManagingUserID != undefined
        ) {
          this.accountManagingUserIds.push(element.accountManagingUserID)

          if (
            element.accountManagingUser.identityInformation &&
            element.accountManagingUser.identityInformation.name
          ) {
            this.accountManagingUserIdsJson.push({
              name: element.accountManagingUser.identityInformation.name,
              accountManagingUserId: element.accountManagingUserID
            })
          }
        }
      }
    })

    if (this.isDebug === true)
      console.debug('ownerIds=' + JSON.stringify(this.accountManagingUserIds))

    this.$store.dispatch('conversation/saveAccountManagerIDs', {
      accountManagerIds: this.accountManagingUserIds,
      accountManagerIdsJson: this.accountManagingUserIdsJson
    })

    this.closeModal()
  },

  closeModal() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggleManagement = false))
    $('#clickclose').click()
  }
}
