<template>
  <span
    v-if="(visible && visible == true) || visible == undefined"
    class="button is-info is-light is-small is-outline is-rounded"
  >
    <router-link :to="to">
      {{ text }}
    </router-link>
  </span>
</template>

<script>
/*
  Example:
    import SimpleButton from '@/components/buttons/Simple'

    <SimpleButton text="button" to="/" />
*/

export default {
  props: {
    text: {
      type: String,
      default: 'Details'
    },
    to: Object,
    visible: {
      Boolean,
      default: true
    },
    styles: {
      type: Object,
      default() {
        return {}
      }
    },
    size: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {}
  },
  computed: {
    buttonClass() {
      const size = this.size
      const buttonType = this.type

      return [
        'button',
        'is-rounded',
        ...(size.length >= 1 ? [size] : []),
        ...(buttonType.length >= 1 ? [buttonType] : [])
      ].join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  box-shadow: 0 0 3px 1px transparent;
  border: 0;
}
</style>
