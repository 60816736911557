import SimpleButton from '@/components/buttons/Simple'
import _get from 'lodash/get'
import { conversationList } from '@/services/Conversations/store'
import moment from 'moment'

export const methods = {
  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    console.debug('global communications unfiltered list=' + JSON.stringify(list))

    this.radio = 'normal'
    let filteredList = []
    filteredList = list

    if (this.showFilters === true) {
      if (this.isQuery === true) {
        this.radio = 'query'

        if (this.queryMessageType && this.queryMessageType !== '') {
          filteredList = filteredList.filter(f => f.privateThreadID === this.queryMessageType)
          if (this.isDebug === true)
            console.debug(
              'message type = ' + this.queryMessageType + ' - ' + JSON.stringify(filteredList)
            )
        }

        if (this.queryMessageSubject && this.queryMessageSubject !== '') {
          filteredList = filteredList.filter(f => f.privateThreadID === this.queryMessageSubject)
          if (this.isDebug === true)
            console.debug(
              'message subject = ' + this.queryMessageSubject + ' - ' + JSON.stringify(filteredList)
            )
        }

        if (this.queryAuthor && this.queryAuthor !== '') {
          filteredList = filteredList.filter(f => f.author === this.queryAuthor)
          if (this.isDebug === true)
            console.debug('author = ' + this.queryAuthor + ' - ' + JSON.stringify(filteredList))
        }

        if (
          this.queryFromCreatedDate &&
          this.queryToCreatedDate &&
          this.queryFromCreatedDate !== '' &&
          this.queryToCreatedDate !== '' &&
          this.queryFromCreatedDate != 'null/null/null' &&
          this.queryToCreatedDate != 'null/null/null'
        ) {
          filteredList = filteredList.filter(
            f =>
              moment(f.createdDate) >= moment(this.queryFromCreatedDate) &&
              moment(f.createdDate) <= moment(this.queryToCreatedDate)
          )
          if (this.isDebug === true)
            console.debug(
              'from to dates = ' +
                this.queryFromCreatedDate +
                ' - ' +
                this.queryToCreatedDate +
                ' -- ' +
                JSON.stringify(filteredList)
            )
        }

        if (this.queryMessage && this.queryMessage !== '') {
          let messages = []
          messages = filteredList.filter(
            f =>
              f.privateThreadMessages &&
              f.privateThreadMessages != undefined &&
              f.privateThreadMessages != null
          )

          if (this.isDebug === true) console.debug('messages = ' + JSON.stringify(messages))

          let ids = ''
          if (messages && messages != undefined) {
            messages.forEach(element => {
              if (
                element &&
                element != undefined &&
                element.privateThreadMessages &&
                element.privateThreadMessages != undefined
              ) {
                if (this.isDebug === true)
                  console.debug('thread messages=' + JSON.stringify(element.privateThreadMessages))
                const threadMessages = element.privateThreadMessages
                threadMessages.forEach(m => {
                  if (m.messageText === this.queryMessage) ids = `${ids}, ${m.privateThreadID}`
                })
              }
            })

            if (ids !== '') {
              filteredList = filteredList.filter(
                f => ids.indexOf(f.privateThreadID.toString()) !== -1
              )
              if (this.isDebug === true)
                console.debug(
                  'message = ' + this.queryMessage + ' - ' + JSON.stringify(filteredList)
                )
            } else {
              filteredList = []
            }
          } else {
            filteredList = []
          }
        }
      }

      if (this.isArchived === true) {
        if (this.isDebug === true) console.debug('archived = ' + JSON.stringify(filteredList))
        this.radio = 'archived'
        filteredList = list.filter(f => f.archivedDate != null)
      } else if (this.isQuery === false && this.isArchived === false) {
        filteredList = filteredList.filter(f => f.archivedDate == null)
      }
    }

    if (this.isDebug === true) console.debug('filteredList=' + JSON.stringify(filteredList))

    var rows = filteredList.map(entry => {
      const dateFormat = 'LL'
      const subject = _get(entry, 'subject', '')
      const author = _get(entry, 'author', '')
      const referenceType = _get(entry, 'referenceType', '')
      let createdDate = _get(entry, ['createdDate'], '')
      let date = createdDate

      try {
        date = moment(createdDate).format(dateFormat)
      } catch (exception) {
        console.error(exception)
      }

      return {
        author: author,
        type: referenceType,
        subject: subject,
        createdDate: {
          date,
          format: dateFormat
        },
        button: {
          component: SimpleButton,
          props: {
            to: {
              name: 'conversationdetail',
              params: {
                id: _get(entry, 'privateThreadID', 0)
              }
            },
            text: 'View Conversation'
          }
        }
      }
    })

    this.rows = rows
  },

  async reloadRequests() {
    console.debug('in reloadRequests...')

    this.isQuery = false
    this.isArchived = false

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (this.radio && this.radio == 'query') {
      this.isQuery = true
    }

    if (this.radio && this.radio == 'archived') {
      this.isArchived = true
    }

    await this.loadMessageTypes()

    await this.loadMessageSubjects()

    await this.reload()

    loadingComponent.close()
  },

  newConversation() {
    this.$router.push('/newconversation')
  },

  async loadConversations() {
    console.debug('in loadConversations()...')
    var referenceType = null
    var referenceID = null

    if (this.workOrderID && this.workOrderID != undefined) {
      referenceType = 'workOrder'
      referenceID = this.workOrderID
    }
    if (this.workOrderRequestID && this.workOrderRequestID != undefined) {
      referenceType = 'workOrderRequest'
      referenceID = this.workOrderRequestID
    }
    if (this.vendorRequestID && this.vendorRequestID != undefined) {
      referenceType = 'vendorRequest'
      referenceID = this.vendorRequestID
    }
    if (this.amenityID && this.amenityID != undefined) {
      referenceType = 'amenity'
      referenceID = this.amenityID
    }
    if (this.architecturalSubmissionID && this.architecturalSubmissionID != undefined) {
      referenceType = 'architecturalSubmission'
      referenceID = this.architecturalSubmissionID
    }
    if (this.apInvoiceID && this.apInvoiceID != undefined) {
      referenceType = 'apInvoice'
      referenceID = this.apInvoiceID
    }
    if (this.violationID && this.violationID != undefined) {
      referenceType = 'violation'
      referenceID = this.violationID
    }
    if (this.suggestionID && this.suggestionID != undefined) {
      referenceType = 'suggestion'
      referenceID = this.suggestionID
    }
    if (this.escrowID && this.escrowID != undefined) {
      referenceType = 'escrow'
      referenceID = this.escrowID
    }
    if (this.ownerID && this.ownerID != undefined) {
      referenceType = 'propertyowner'
      referenceID = this.ownerID
    }
    await conversationList
      .dispatch('getPrivateThreadList', {
        hoaId: this.hoaId,
        vendorGlobalID: this.authUser.vendorGlobalID,
        showOnlyHomeownerThreads:
          this.authUser.ownerUserID !== undefined &&
          this.authUser.ownerUserID &&
          this.authUser.ownerUserID > 0
            ? true
            : false,
        referenceType,
        referenceID
      })
      .then(({ list }) => {
        if (list) {
          this.determineRows(list)
        }
      })
  },

  async loadMessageTypes() {
    if (this.isDebug == true) console.debug('in loadMessageTypes()...')
    await conversationList
      .dispatch('getMessageTypes', {
        hoaId: this.hoaId,
        vendorGlobalID: this.authUser.vendorGlobalID,
        showOnlyHomeownerThreads:
          this.authUser.ownerUserID && this.authUser.ownerUserID != undefined ? true : false
      })
      .then(({ list }) => {
        this.messageTypes = list
      })
  },

  async loadMessageSubjects() {
    if (this.isDebug == true) console.debug('in loadMessageSubjects()...')
    await conversationList
      .dispatch('getMessageSubjects', {
        hoaId: this.hoaId,
        vendorGlobalID: this.authUser.vendorGlobalID,
        showOnlyHomeownerThreads:
          this.authUser.ownerUserID && this.authUser.ownerUserID != undefined ? true : false
      })
      .then(({ list }) => {
        this.messageSubjects = list
      })
  },

  addRecipient(recipient) {
    console.debug('add recipient=' + recipient)

    let added = false

    if (this.recipientsSelected) {
      const list = this.recipientsSelected
      for (let a = 0; a < list.length; a++) {
        const tempHolder = list[a]

        if (tempHolder.recipient === recipient) {
          added = true
          break
        }
      }
    }

    if (!added) {
      if (recipient === 'Homeowners') {
        if (
          this.selectedOwnerIDsJson &&
          Array.isArray(this.selectedOwnerIDsJson) &&
          this.selectedOwnerIDsJson.length > 0
        ) {
          Array.from(this.selectedOwnerIDsJson).forEach(element => {
            if (element) {
              const newRecipient = `Homeowner - ${element.name}`
              const isThisAlreadyInArray = this.recipientsSelected.filter(
                f => f.recipient === newRecipient
              )

              if (isThisAlreadyInArray && isThisAlreadyInArray.length === 0) {
                this.recipientsSelected.push({
                  recipient: newRecipient,
                  type: 'homeowner',
                  id: element.ownerID
                })
              }
            }
          })
        }
      } else if (recipient === 'Vendors') {
        if (
          this.selectedVendorIDsJson &&
          Array.isArray(this.selectedVendorIDsJson) &&
          this.selectedVendorIDsJson.length > 0
        ) {
          Array.from(this.selectedVendorIDsJson).forEach(element => {
            if (element) {
              const newRecipient = `Vendor - ${element.name}`
              const isThisAlreadyInArray = this.recipientsSelected.filter(
                f => f.recipient === newRecipient
              )

              if (isThisAlreadyInArray && isThisAlreadyInArray.length === 0) {
                this.recipientsSelected.push({
                  recipient: newRecipient,
                  type: 'vendor',
                  id: element.vendorID
                })
              }
            }
          })
        }
      } else if (recipient === 'Management Portal Users') {
        console.log('Management Portal Users=' + JSON.stringify(this.selectedAccountManagerIDsJson))
        if (
          this.selectedAccountManagerIDsJson &&
          Array.isArray(this.selectedAccountManagerIDsJson) &&
          this.selectedAccountManagerIDsJson.length > 0
        ) {
          Array.from(this.selectedAccountManagerIDsJson).forEach(element => {
            if (element) {
              const newRecipient = `Management Portal User - ${element.name}`
              const isThisAlreadyInArray = this.recipientsSelected.filter(
                f => f.recipient === newRecipient
              )

              console.log('addRecipient manager=' + JSON.stringify(element))

              if (isThisAlreadyInArray && isThisAlreadyInArray.length === 0) {
                this.recipientsSelected.push({
                  recipient: newRecipient,
                  type: 'management portal user',
                  id: element.accountManagingUserId
                })
              }
            }
          })
        }
      } else {
        this.recipientsSelected.push({
          recipient
        })
      }
    }

    this.toggleRecipients = true

    console.debug('recipientsSelected=' + JSON.stringify(this.recipientsSelected))
  },

  async processSearch() {
    if (this.isDebug === true) console.debug('...in processSearch')

    if (this.statusFilter && this.statusFilter == 'query') {
      if (this.isDebug === true)
        console.debug('this.$refs.fromCreatedDateComponent=' + this.$refs.fromCreatedDateComponent)

      if (
        this.$refs.fromCreatedDateComponent &&
        this.$refs.toCreatedDateComponent &&
        this.$refs.fromCreatedDateComponent != undefined &&
        this.$refs.toCreatedDateComponent != undefined
      ) {
        const from = this.$refs.fromCreatedDateComponent.getValue()
        const to = this.$refs.toCreatedDateComponent.getValue()
        this.queryFromCreatedDate = `${from.month}/${from.day}/${from.year}`
        this.queryToCreatedDate = `${to.month}/${to.day}/${to.year}`

        if (this.isDebug === true)
          console.debug(
            'from to dates=' + this.queryFromCreatedDate + ' = ' + this.queryToCreatedDate
          )
      }
    }

    await this.loadConversations()
  },

  processDetail(vendorID) {
    if (vendorID) {
      this.$router.push({
        name: 'vendor.vendorContacts',
        params: { id: vendorID }
      })
    }
  },

  async reload() {
    this.loading = true

    this.rows = []
    await this.loadConversations()

    this.loading = false
  }
}
