export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    openOnFocus: true,
    accountManagingUserList: [],
    accountManagingUserListCopy: [],
    accountManagingUserIds: [],
    accountManagingUserIdsJson: [],
    checkboxPosition: 'left',
    checkedRows: [],
    toggleManagement: false,
    filterBy: ''
  }
}
