import { conversationList } from '@/services/Conversations/store'
import { notifyError } from '@/services/notify'
import $ from 'jquery'

export const methods = {
  async reload() {
    this.loading = true

    this.$store.dispatch('conversation/saveBoardCommitteeID', {
      committeeID: 0
    })

    this.getCommitteesDropDown()

    this.loading = false
  },

  async getCommitteesDropDown() {
    if (this.isDebug == true)
      console.debug(
        'in getCommitteesDropDown()...' + this.hoaId && this.hoaId > 0
          ? this.hoaId
          : this.VendorHoaID
      )
    await conversationList
      .dispatch('loadCommittees', {
        hoaID: this.hoaId && this.hoaId > 0 ? this.hoaId : this.VendorHoaID,
        isBoardMember: true
      })
      .then(({ list }) => {
        if (list) {
          this.boardList = list
        }
      })
  },

  open() {
    this.subject = ''
    this.isOpen = true
    this.loading = false
  },

  async processSubmit() {
    if (!this.committeeID || this.committeeID == undefined || this.committeeID <= 0) {
      notifyError('A Board of Directors Committee selection is required.')
      return
    }

    this.$store.dispatch('conversation/saveBoardCommitteeID', {
      committeeID: this.committeeID
    })

    this.closeModal()
  },

  closeModal() {
    // auto-close modal
    if (this.isDebug === true) console.debug('committeeID=' + this.committeeID)

    this.$emit('update:toggle', (this.toggleBoard = false))
    $('#clickclose').click()
  }
}
