/*
  Example:
    import parseAddress from '@/utilities/address/parse'

    const parsedAddress = parseAddress ({
      address: {
        addressOne
        addressTwo

        foreignAddress

        // foreign address fields
        addressThree
        addressFour
        addressFive

        // USA address fields
        city
        state
        postalCode
      }
    })
*/

import _get from 'lodash/get'

export default ({ address }) => {
  if (typeof address !== 'object' || address === null) {
    // console.error('address/parse can only parse addresses that are iterable objects, got', address)
    return ''
  }

  /*
    false -> USA Address
      addressOne
      addressTwo (if non-empty)
      city, state postalCode

    true -> foreign
      addressOne
      addressTwo
      addressThree
      addressFour (if non-empty)
      addressFive (if non-empty)
  */
  var isForeign = _get(address, 'foreignAddress', false)

  if (!isForeign) {
    let _address = _get(address, 'addressOne', '')

    if (_address && _address.length > 0) {
      _address += ','
    }

    const addressTwo = _get(address, 'addressTwo', null)
    if (typeof addressTwo === 'string' && addressTwo.length > 0) {
      _address += ' ' + addressTwo
    }

    const addressLine1 = _get(address, 'addressLine1', '')
    if (typeof addressLine1 === 'string' && addressLine1.length > 0) {
      _address += ' ' + addressLine1 + ','
    }

    const addressLine2 = _get(address, 'addressLine2', '')
    if (typeof addressLine2 === 'string' && addressLine2.length > 0) {
      _address += ' ' + addressLine2
    }

    const city = _get(address, 'city', null)
    if (typeof city === 'string') {
      _address += ' ' + city + ','
    }

    const state = _get(address, 'state', null)
    if (typeof state === 'string') {
      _address += ' ' + state
    }

    const postalCode = _get(address, 'postalCode', null)
    if (typeof postalCode === 'string') {
      _address += ' ' + postalCode
    }

    const zip = _get(address, 'zip', null)
    if (typeof zip === 'string') {
      _address += ' ' + zip
    }

    return _address
  } else {
    let _address = _get(address, 'addressOne', '')
    const addressTwo = _get(address, 'addressTwo', '')
    const addressThree = _get(address, 'addressThree', '')
    const addressFour = _get(address, 'addressFour', '')
    const addressFive = _get(address, 'addressFive', '')

    if (typeof addressTwo === 'string' && addressTwo.length > 0) {
      _address += ' ' + addressTwo
    }
    if (typeof addressThree === 'string' && addressThree.length > 0) {
      _address += ' ' + addressThree
    }
    if (typeof addressFour === 'string' && addressFour.length > 0) {
      _address += ' ' + addressFour
    }
    if (typeof addressFive === 'string' && addressFive.length > 0) {
      _address += ' ' + addressFive
    }

    return _address
  }
}
