<template>
  <div message-modal>
    <div class="is-size-4" v-if="committeeList && recipient == 'Add Other Committee'">
      Committee
    </div>
    <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(processSubmit)">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <div
                  v-if="committeeList"
                  class="column is-12"
                  style="padding:10px; white-space:nowrap;"
                >
                  <valid-select
                    placeholder="<-Select a Committee->"
                    vid="committeeID"
                    v-model="committeeID"
                    aria-required="A Committee is required"
                    rules="required"
                    label="Select a Committee"
                    title="<-Select a Committee->"
                    required
                  >
                    <option
                      v-for="option in committeeList"
                      :value="option.committeeID"
                      :key="option.committeeID"
                      >{{ option.name }}</option
                    >
                  </valid-select>
                </div>
                <div class="pt-5">
                  <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                    Select
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidSelect from '@/components/inputs/ValidSelect'

export default {
  props: {
    recipient: String,
    VendorHoaID: Number
  },

  components: {
    ValidSelect,
    ValidationObserver,
    ValidationProvider
  },

  mounted() {
    if (this.isDebug === true) console.debug('vendorHoaID=' + this.VendorHoaID)

    this.reload()
    this.committeeID = 0
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
