<template>
  <b-select v-model="recipientSelection">
    <option v-for="option in conversationRecipients" :value="option.value" :key="option.value">
      {{ option.label }}
    </option>
  </b-select>
</template>

<script>
import { conversationList } from '@/services/Conversations/store'
import { appId } from '@/config'

export default {
  props: {
    filterBy: String,
    addValue: Number,
    addLabel: String,
    onChange: {
      default() {},
      type: Function
    }
  },
  data() {
    return {
      isDebug: true,
      conversationRecipients: [],
      recipientSelection: 'Board of Directors',
      scopre: ''
    }
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    getValue() {
      return this.recipientSelection
    },
    async reload() {
      await conversationList.dispatch('loadConversationRecipients').then(({ list }) => {
        if (list) {
          let tempList = list
          this.scope = appId()
          if (this.scope !== undefined && this.scope && this.scope === 'homeowner') {
            const homeOwnerSpliceValue = 'Homeowners'
            tempList = this.removeValue(tempList, homeOwnerSpliceValue)

            const vendorSpliceValue = 'Vendors'
            tempList = this.removeValue(tempList, vendorSpliceValue)
          }

          this.conversationRecipients = tempList

          if (this.isDebug === true && this.addValue && this.addLabel)
            console.debug('addValue and addLabel=' + this.addValue + ', addLabel=' + this.addLabel)

          if (this.addValue && this.addLabel) {
            this.conversationRecipients.push({ value: this.addLabel, label: this.addLabel })
          }
        }
      })
    },
    removeValue(arr, value) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].value === value) {
          arr.splice(i, 1)
          break
        }
      }
      return arr
    }
  },
  watch: {
    recipientSelection() {
      this.onChange({
        recipient: this.recipientSelection
      })
    },
    addValue() {
      this.reload()
    }
  }
}
</script>
